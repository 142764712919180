// /* eslint-disable react/style-prop-object */
import { useAuth0 } from '@auth0/auth0-react';
import styled, { css } from 'styled-components/macro';
import { Spacer } from '../../components/Spacer';
import { Tooltip } from '../../components/Tooltip';
import AboutDialog from './components/About';
import { LogoutButton } from './components/LogoutButton';
import { PixLogo } from './components/PixLogo';

const SvgSize = css`
  width: 1rem;
  height: 1rem;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-variant: all-small-caps;
  font-weight: 400;
  min-width: fit-content;
  margin-left: auto;
  color: #8f8f8fbd;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-variant: all-small-caps;
  font-weight: 500;
  min-width: fit-content;
  color: #8f8f8fbd;
  padding-bottom: 4px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  min-height: 3rem;
  align-items: center;
  align-content: center;
  z-index: 10;
  flex: 0 0 auto;
  padding: 0;
  /* border-bottom: 1px solid ${(props) => props.theme.colors.grey500}; */
  border-collapse: collapse;
  background-color: ${(props) => props.theme.colors.grey900};
  svg {
    ${SvgSize}
  }

  img {
    display: block;
    width: 1.5rem;
    opacity: 0.5;
    margin-left: 0.75rem;

    &:hover {
      opacity: 1;
    }
  }
`;

export default function Header() {
  const { user } = useAuth0();
  return (
    <HeaderWrapper>
      <AboutDialog buttonComponent={<PixLogo />} />

      <Spacer span={0.2} />
      <Title>Device Manager</Title>
      <Spacer />
      <User>
        {user?.name}
        <Tooltip title="Logout" shouldWrap placement="bottom">
          <LogoutButton />
        </Tooltip>
      </User>
    </HeaderWrapper>
  );
}
