/**
 * An enum for storing the different headers to be attached to PIX API requests.
 */
enum PixHeaders {
  SessionId = 'x-pix-session-id',
  AppKey = 'x-pix-app-key',
  SessionTTL = 'x-pix-session-ttl',
  CorrelationChain = 'x-pix-correlation-chain',
  ApiKey = 'x-api-key',
  UserId = 'x-user-id',
  DomainId = 'x-domain-id',
  SessionJWT = 'x-pix-session-jwt',
  AccessToken = 'x-pix-access-token',
  RefreshToken = 'x-pix-refresh-token',
  Dev01TemporaryAuthUser = 'x-dev-test-user'
}

const LocalStorageKeys = {
  SessionId: `${PixHeaders.SessionId}`,
  SessionTTL: `${PixHeaders.SessionTTL}`,
  AccessTokenTTL: `${PixHeaders.AccessToken}-ttl`,
  AccessToken: `${PixHeaders.AccessToken}`,
  RefreshToken: `${PixHeaders.RefreshToken}`,
  User: `x-pix-user`,
  ActiveProject: `x-pix-active-project`
};

export { PixHeaders, LocalStorageKeys };
