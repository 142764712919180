import {
  retrieveUser,
  retrieveAccessToken,
  retrieveRefreshToken,
  retrieveSessionId,
  retrieveActiveProject
} from '../authorization/persistence';
import { useLocalstorageState } from './useLocalstorageState';
import { LocalStorageKeys } from '../api/constants';
import type { TokenSessionType, AccessTokenTTLType, SessionTTLType, IActiveUser } from './types';
import type { IActiveProject } from '../features/ProjectBrowser/types';

export const useLocalStorage = () => {
  const [accessToken, setAccessToken, clearAccessToken] = useLocalstorageState<TokenSessionType>(
    LocalStorageKeys.AccessToken,
    retrieveAccessToken()
  );
  const [sessionId, setSessionId, clearSessionId] = useLocalstorageState<TokenSessionType>(
    LocalStorageKeys.SessionId,
    retrieveSessionId()
  );
  const [refreshToken, setRefreshToken, clearRefreshToken] = useLocalstorageState<TokenSessionType>(
    LocalStorageKeys.RefreshToken,
    retrieveRefreshToken()
  );
  const [accessTokenTTL, setAccessTokenTTL, clearAccessTokenTTL] = useLocalstorageState<AccessTokenTTLType | string>(
    LocalStorageKeys.AccessTokenTTL
  );
  const [sessionTTL, setSessionTTL, clearSessionTTL] = useLocalstorageState<SessionTTLType | string>(
    LocalStorageKeys.SessionTTL,
    null
  );
  const [user, setUser, clearUser] = useLocalstorageState<IActiveUser | null>(LocalStorageKeys.User, retrieveUser());
  const [activeProject, setActiveProject, clearActiveProject] = useLocalstorageState<IActiveProject | null>(
    LocalStorageKeys.ActiveProject,
    retrieveActiveProject()
  );

  return {
    accessToken,
    sessionId,
    refreshToken,
    accessTokenTTL,
    sessionTTL,
    user,
    activeProject,
    setAccessToken,
    clearAccessToken,
    setSessionId,
    clearSessionId,
    setRefreshToken,
    clearRefreshToken,
    setAccessTokenTTL,
    clearAccessTokenTTL,
    setSessionTTL,
    clearSessionTTL,
    clearUser,
    setUser,
    setActiveProject,
    clearActiveProject
  };
};
