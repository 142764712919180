/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { associateDevice } from '../../../api/deviceApi';
import { useAsync } from '../../../hooks/useAsync';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useHandleAccessTokenExpired } from './useHandleAccessTokenExpired';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUseAssociateDeviceProps {
  id: string;
  payload: IDevicePayload;
  immediate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDevicePayload {
  project_id?: string;
  folder_id?: string;
  brand?: string;
  model?: string;
  version?: string;
  type?: string;
  hostname?: string;
  name?: string;
  folder_name?: string;
  project_name?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IRequestProps {
  id: string;
  payload: IDevicePayload;
}
export function useAssociateDevice({ immediate = false }: IUseAssociateDeviceProps) {
  const { accessToken } = useLocalStorage();
  const navigate = useNavigate();

  const associateDeviceWithTokenExpired = useHandleAccessTokenExpired(associateDevice);
  const { getAccessTokenSilently } = useAuth0();
  const request = async () => {
    const token = await getAccessTokenSilently();
    return ({ id: rid, payload: rpayload }: IRequestProps) =>
      associateDeviceWithTokenExpired((accessToken as string) || token, rid, rpayload);
  };

  const { execute, status, value, error } = useAsync(request(), immediate);

  React.useEffect(() => {
    if (error !== null) {
      if (error === 'Authorization error') {
        // console.info(`Authorization error`);
        navigate('sign-out');
      } else {
        // console.info(`List devices error: ${error}`);
      }
    }
  }, [error, navigate]);
  return { execute, status, value, error };
}
