/* eslint-disable camelcase */
import React from 'react';
import { useNavigate } from 'react-router';
import { getDevices } from '../../../api/deviceApi';
import { useAsync } from '../../../hooks/useAsync';
import { useHandleAccessTokenExpired } from './useHandleAccessTokenExpired';
import type { IDevice } from '../types';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useAuth0 } from '@auth0/auth0-react';
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUseListDeviceProps {
  immediate?: boolean;
}

export function useListDevices({ immediate = false }: IUseListDeviceProps) {
  const { accessToken } = useLocalStorage();
  const navigate = useNavigate();
  const getDevicesWithTokenExpired: (arg: string) => Promise<IDevice[]> = useHandleAccessTokenExpired(
    getDevices as (accessToken: string) => Promise<IDevice[]>
  );

  const { getAccessTokenSilently } = useAuth0();

  const request = async () => {
    const token = await getAccessTokenSilently();
    return () => getDevicesWithTokenExpired((accessToken as string) || token);
  };

  const { execute, status, value, error } = useAsync<IDevice[]>(request(), immediate);

  React.useEffect(() => {
    if (error !== null) {
      if (error === 'Authorization error') {
        // console.info(`Authorization error`);
      } else {
        // console.info(`List devices error: ${error}`);
      }
    }
  }, [error, navigate]);

  return { execute, status, value, error };
}
