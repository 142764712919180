/* eslint-disable react/style-prop-object */
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import styled from 'styled-components';

import { ApplicationButton, ApplicationButtonIcon } from '../../Header/components/Button';

export function LinkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
        <path
          transform="scale(1, -1) translate(0, -250)"
          fill="#bdbdbd"
          d="M225.25439,82.74512a51.65924,51.65924,0,0,1-15.22949,36.76953L181.74072,147.7998a52.0625,52.0625,0,0,1-73.54,0,8.00053,8.00053,0,0,1,11.31446-11.31445,36.04088,36.04088,0,0,0,50.91211,0l28.2832-28.28515A35.99926,35.99926,0,0,0,147.79932,57.29L128.00049,77.08887A8.00053,8.00053,0,1,1,116.686,65.77441l19.79882-19.79882a51.99951,51.99951,0,0,1,88.76953,36.76953Zm-97.25488,96.166L108.20068,198.71A35.99926,35.99926,0,1,1,57.28955,147.7998l28.2832-28.28515a36.03821,36.03821,0,0,1,50.91211,0,8.00053,8.00053,0,1,0,11.31446-11.31445,52.0625,52.0625,0,0,0-73.54,0L45.9751,136.48535a52.00031,52.00031,0,0,0,73.54,73.53906L139.314,190.22559a8.00053,8.00053,0,0,0-11.31445-11.31446Z"
        />
      </svg>
    </SvgIcon>
  );
}
export function LinkBreakIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 326.157 326.157">
        <g id="XMLID_86_" fill="white">
          <path
            id="XMLID_87_"
            d="M206.342,275.032c-8.284,0-15,6.716-15,15v21.124c0,8.284,6.716,15,15,15s15-6.716,15-15v-21.124 C221.342,281.748,214.626,275.032,206.342,275.032z"
          />{' '}
          <path
            id="XMLID_88_"
            d="M311.158,191.343l-21.125-0.001c-0.001,0,0,0-0.001,0c-8.283,0-15,6.716-15,14.999 c0,8.284,6.715,15.001,14.999,15.001l21.125,0.001c0.001,0,0.001,0,0.001,0c8.283,0,15-6.716,15-14.999 C326.157,198.06,319.442,191.343,311.158,191.343z"
          />{' '}
          <path
            id="XMLID_89_"
            d="M276.127,254.913c-5.858-5.858-15.355-5.857-21.214-0.001c-5.857,5.858-5.858,15.355-0.001,21.214 l14.938,14.938c2.93,2.93,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.607-4.393c5.857-5.858,5.858-15.355,0.001-21.214 L276.127,254.913z"
          />{' '}
          <path
            id="XMLID_90_"
            d="M119.813,51.124c8.284,0,15-6.716,15-15V15c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v21.124 C104.813,44.408,111.529,51.124,119.813,51.124z"
          />{' '}
          <path
            id="XMLID_91_"
            d="M36.124,134.815c8.284,0,15-6.716,15-14.999c0-8.284-6.715-15.001-15-15.001L15,104.814h0 c-8.284,0-15,6.716-15,14.999C0,128.097,6.715,134.814,15,134.814L36.124,134.815C36.124,134.815,36.124,134.815,36.124,134.815z"
          />{' '}
          <path
            id="XMLID_92_"
            d="M50.029,71.243c2.929,2.93,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.607-4.393 c5.858-5.858,5.858-15.355,0-21.214L56.305,35.092c-5.857-5.858-15.355-5.857-21.213-0.001c-5.858,5.858-5.858,15.355,0,21.214 L50.029,71.243z"
          />{' '}
          <path
            id="XMLID_93_"
            d="M194.898,237.323c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0l-42.423,42.423 c-0.002,0.002-0.003,0.003-0.005,0.005c-17.543,17.545-46.092,17.546-63.638,0s-17.546-46.095,0-63.641l42.426-42.426 c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0l-42.426,42.426c-29.243,29.242-29.243,76.823,0,106.066 c14.166,14.166,33,21.967,53.032,21.967c0.001,0,0,0,0.001,0c20.032,0,38.867-7.802,53.032-21.967 c0.002-0.002,0.003-0.003,0.005-0.005L194.898,237.323z"
          />{' '}
          <path
            id="XMLID_102_"
            d="M279.75,46.405c-14.164-14.165-32.998-21.967-53.031-21.967c-0.001,0,0.001,0-0.001,0 c-20.032,0-38.867,7.802-53.032,21.967l-0.001,0.001l-0.001,0.001l-42.425,42.426c-5.858,5.857-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394l42.425-42.426l0.001-0.001l0.001-0.001 c8.499-8.499,19.8-13.18,31.82-13.18c12.02,0,23.319,4.681,31.818,13.18c17.546,17.546,17.546,46.095,0,63.641l-42.426,42.426 c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394l42.426-42.426 C308.993,123.23,308.993,75.648,279.75,46.405z"
          />{' '}
        </g>{' '}
        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
        <g> </g> <g> </g> <g> </g>{' '}
      </svg>
    </SvgIcon>
  );
}

export function LinkAddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 71.378 71.379">
        <g>
          {' '}
          <g>
            {' '}
            <g>
              <path
                fill="grey"
                d="M17.166,44.724c0.672,0.672,1.12,1.118,1.792,1.565c-0.896-4.031-0.672-8.062,0.448-12.095l-7.616-7.393 c-3.808-3.809-3.584-10.305,0.672-14.336c4.032-4.031,10.528-4.256,14.336-0.672l11.647,11.648 c3.809,3.809,3.584,10.305-0.672,14.335c-1.344,1.344-2.913,2.24-4.479,2.688c-0.224,2.019,0.448,3.81,1.792,5.151l2.688,2.688 c2.24-0.896,4.479-2.465,6.271-4.256c7.616-7.615,7.841-19.711,0.672-27.104L33.069,5.3C25.678-2.092,13.582-1.645,5.965,5.973 c-7.616,7.615-7.84,19.712-0.672,27.104L17.166,44.724z"
              />
              <path
                fill="grey"
                d="M65.998,38.45L54.351,26.805c-0.673-0.672-1.119-1.12-1.792-1.568c0.896,4.032,0.673,8.064-0.448,12.097l7.616,7.614 c3.808,3.81,3.584,10.306-0.672,14.336c-4.032,4.032-10.528,4.257-14.337,0.673L33.069,48.308 c-3.808-3.81-3.584-10.306,0.672-14.335c1.344-1.344,2.913-2.24,4.48-2.688c0.224-2.016-0.448-3.808-1.792-5.151l-2.913-3.137 c-2.24,0.896-4.48,2.465-6.272,4.256c-7.616,7.616-7.84,19.711-0.672,27.104l11.648,11.646 c7.392,7.394,19.486,6.943,27.104-0.672C72.941,57.715,73.391,45.842,65.998,38.45z"
              />
              <polygon
                fill="white"
                points="14.925,48.53 8.207,48.53 8.207,56.594 0.143,56.594 0.143,63.315 8.207,63.315 8.207,71.379 14.925,71.379 14.925,63.315 22.99,63.315 22.99,56.594 14.925,56.594 			"
              />{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
        <g> </g> <g> </g> <g> </g>{' '}
      </svg>
    </SvgIcon>
  );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILinkButton {
  onClick?: () => void;
}
export function LinkButton(props: ILinkButton) {
  return (
    <ApplicationButton
      style={{ backgroundColor: 'transparent', borderBottom: 'none' }}
      opened={false}
      active={false}
      {...props}>
      <ApplicationButtonIcon type="button" disabled={false}>
        <LinkAddIcon />
      </ApplicationButtonIcon>
    </ApplicationButton>
  );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILinkedkButton {
  [x: string]: unknown;
  onClick?: () => void;
}

const ApplicationButtonPrint = styled(ApplicationButton)`
  &:hover {
    cursor: default;
  }
`;
const ApplicationButtonIconPrint = styled(ApplicationButtonIcon)`
  &:hover {
    cursor: default;
  }
`;
export function LinkedButton(props: ILinkedkButton) {
  const { disabled, opened, active } = props;
  return (
    <ApplicationButtonPrint
      style={{ backgroundColor: 'transparent', borderBottom: 'none' }}
      opened={!!opened}
      active={!!active}
      {...props}>
      <ApplicationButtonIconPrint type="button" disabled={!!disabled}>
        <LinkIcon />
      </ApplicationButtonIconPrint>
    </ApplicationButtonPrint>
  );
}
