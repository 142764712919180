import styled from 'styled-components/macro';

const PixLogoContainer = styled.div``;

export function PixLogo() {
  return (
    <PixLogoContainer>
      <img src={`${process.env.PUBLIC_URL}/pix_logo.png`} alt="PIX Logo" />
    </PixLogoContainer>
  );
}
