/* eslint-disable camelcase */
import useFetchApi from '../useFetchApi';
import type { IProjects } from './types';
import { NEW_API_BASE_URL } from '../../../../utils/deviceManagerConfig';

const NEW_API_HOST_URL = NEW_API_BASE_URL;
const PROJECTS_ENDPOINT = 'admin-v2/projects';

export default function useProjects() {
  const [data, loading, error, fetchProjects] = useFetchApi<IProjects>();

  return [
    data,
    loading,
    error,
    () =>
      fetchProjects(`${NEW_API_HOST_URL}/${PROJECTS_ENDPOINT}`, {
        headers: {
          'content-type': 'application/json'
        }
      })
  ] as const;
}
