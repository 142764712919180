/* eslint-disable @typescript-eslint/naming-convention */
// FolderTree.tsx
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderRounded';
import Folder from './components/FolderIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { defaultTheme as theme } from '../../components/theme';
import type { IProject } from './hooks/newApi/types';
import type { IDevice } from '../DeviceBrowser/types';
import { NEW_API_BASE_URL } from '../../utils/deviceManagerConfig';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Styles
const FolderTreeWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 24px;
`;

const FolderNodeWrapper = styled.li`
  margin: 4px 0;
`;

const FolderName = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${theme.colors.grey500};
  }
  ${(props) =>
    props.selected &&
    `
    background-color: ${theme.colors.grey500};
  `}
  padding: 4px 4px;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const ChildrenWrapper = styled.ul`
  list-style: none;
  padding-left: 24px;
`;

const FolderIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FolderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FolderText = styled.span<{ selected?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes?.lg};
  font-weight: 400;
  font-variant-caps: all-small-caps;
  padding-left: calc(1 * 6px);
  color: ${(props) => (props.selected ? '#e0e0e0' : '#9e9e9e')};
  &:hover {
    color: #e0e0e0;
    cursor: pointer;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 490px;
`;

// Interfaces & Types

interface FolderTreeProps {
  projectId: string;
}
interface FolderTreeProps {
  // onChangeProjectFolder: (projectId: string, folderId: string) => void;
  onChangeProjectFolder: (spf: Folder | null) => void;
  activeProject?: IProject;
  selectedProjectFolder: Partial<IDevice>;
  setActiveProject: (project: IProject) => void;
}

// interface IFolderTreeProps {
//     rootFolderId: string;
//     onChangeProjectFolder?: (spf: INode | null) => void;
//     activeProject?: IActiveProject;
//     selectedProjectFolder: Partial<IDevice>;
//     setActiveProject: (ap: IActiveProject) => void;
// }

type Asset = {
  id: string;
};

type Breadcrumb = {
  folder_id: string;
  folder_name: string;
};

type Item = {
  id: string;
  name: string;
  description: null | string;
  created_by: string;
  extension: string;
  item_type: null | string;
  item_type_id: null | string;
  created_on: string;
  modified_on: string;
  modified_by: string;
  parent_id: string;
  processing_status: string;
  progress: null | string | number;
  size: number;
  assets: Asset[];
};

interface FolderBase {
  id: string;
  created_by: string;
  created_on: string;
  modified_by: string;
  modified_on: string;
  name: string;
  parent_id: string | null;
  is_empty: boolean;
  breadcrumbs: Breadcrumb[];
  is_root: boolean;
  contents?: {
    folders: Folder[];
    items: Item[];
    folder_count: number;
    item_count: number;
  };
}

type Folder = FolderBase & { parent_id: string };
type RootFolder = FolderBase & { parent_id: null };
export type IFolder = Folder;
type FolderType<T> = T extends RootFolder ? T : Folder;

interface IFolderRequestResponse<T> {
  end_cursor: string | null;
  has_next_page: boolean;
  folders: T[];
}

interface FolderNodeProps<T> {
  folder: FolderType<T>;
  folderId: string;
  parentFolder?: Folder;
  folderContents?: FolderBase['contents'];
  projectId: string;
  projectName?: string;
  selectedFolderId: string;
  setSelectedFolderId: (folderId: string) => void;
  defaultExpandIcon?: JSX.Element;
  defaultCollapseIcon?: JSX.Element;
  defaultEndIcon?: JSX.Element;
  handleIconClick?: (folder: FolderType<T>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FolderNodeProps<T> {
  onChangeProjectFolder: (spf: Folder | null) => void;
  activeProject?: IProject;
  selectedProjectFolder: Partial<IDevice>;
  setActiveProject: (project: IProject) => void;
}

const normalizeResponseData = (response: IFolderRequestResponse<Folder | RootFolder>) => {
  const contents = response.folders && response.folders.length ? response.folders[0].contents : undefined;
  const folders = contents && contents.folders.length > 0 ? contents.folders : [];
  return { contents, folders };
};

function FolderNodeDisplay<T>({
  CollapseIcon,
  ExpandIcon,
  expanded,
  folder,
  handleFolderClick,
  hasChildren,
  isSelected,
  loading,
  children
}: {
  CollapseIcon: () => JSX.Element;
  ExpandIcon: () => JSX.Element;
  expanded: boolean;
  folder: FolderType<T>;
  handleFolderClick: (folder: Folder) => void;
  hasChildren: boolean;
  isSelected: boolean;
  loading: boolean;
  children?: JSX.Element;
}): JSX.Element {
  return (
    <FolderName onClick={() => handleFolderClick(folder as Folder)} selected={isSelected}>
      <IconWrapper>
        {children === undefined ? (
          loading ? (
            <LoadingSpinner />
          ) : isSelected ? (
            <FolderIconWrapper>
              <>
                {hasChildren && expanded ? (
                  <>
                    <ExpandIcon />
                    <FolderOpenIcon color="primary" fontSize="large" />
                  </>
                ) : hasChildren && !expanded ? (
                  <>
                    <CollapseIcon />
                    <FolderIcon fontSize="large" color="primary" />
                  </>
                ) : (
                  <FolderIcon fontSize="large" color="primary" />
                )}
              </>
            </FolderIconWrapper>
          ) : expanded ? (
            <FolderIconWrapper>
              <>
                {hasChildren && <ExpandIcon />}
                <FolderOpenIcon fontSize="large" />
              </>
            </FolderIconWrapper>
          ) : (
            <FolderIconWrapper>
              <>
                {hasChildren && <CollapseIcon />}
                <FolderIcon fontSize="large" />
              </>
            </FolderIconWrapper>
          )
        ) : (
          children
        )}
      </IconWrapper>
    </FolderName>
  );
}

function FolderNode<T>({
  folder,
  folderId,
  projectId,
  selectedFolderId,
  setSelectedFolderId,
  defaultExpandIcon,
  defaultCollapseIcon,
  handleIconClick,
  activeProject,
  setActiveProject,
  selectedProjectFolder,
  onChangeProjectFolder
}: FolderNodeProps<T>) {
  const [expanded, setExpanded] = useState(false);
  const [children, setChildren] = useState<Folder[]>([]);
  const [contents, setContents] = useState<Folder['contents']>();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    return token;
  }, [getAccessTokenSilently]);

  const fetchData = React.useCallback(
    async function fetchChildData() {
      setLoading(true);
      const clientOptions = {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`
        }
      };
      const response = await fetch(`${NEW_API_BASE_URL}/content-v2/${projectId}/folders/${folderId}`, clientOptions);
      const data: IFolderRequestResponse<Folder> = await response.json();
      const { contents, folders } = normalizeResponseData(data);
      setChildren(folders);
      setContents(contents);
      setLoading(false);
    },
    [folderId, getAccessToken, projectId]
  );

  const handleClick = React.useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
    if (!expanded && !children.length) {
      fetchData();
    }
  }, [expanded, children.length, fetchData]);

  // Fetch children folders upon rendering, but only if they haven't been fetched before
  useEffect(() => {
    if (!children.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.length]);

  const handleSelect = () => {
    setSelectedFolderId(folder.id);
  };

  const isSelected = selectedFolderId === folder.id;

  const hasChildren = React.useMemo(() => !!(contents && contents.folder_count > 0), [contents]);

  const handleIconClicked = () => {
    if (handleIconClick) {
      handleIconClick(folder);
    }
  };

  const ExpandIcon = () =>
    defaultExpandIcon ? (
      <div onClick={handleIconClicked}>{defaultExpandIcon}</div>
    ) : (
      <div onClick={handleIconClicked}>
        <ArrowDropDownIcon />
      </div>
    );

  const CollapseIcon = () =>
    defaultCollapseIcon ? (
      <div onClick={handleIconClicked}>{defaultExpandIcon}</div>
    ) : (
      <div onClick={handleIconClicked}>
        <ArrowRightIcon />
      </div>
    );

  const handleFolderClick = (folder: Folder) => {
    handleClick();
    handleSelect();
    onChangeProjectFolder(folder);
  };

  return (
    <FolderNodeWrapper>
      <FolderNodeDisplay
        CollapseIcon={CollapseIcon}
        ExpandIcon={ExpandIcon}
        expanded={expanded}
        folder={folder}
        handleFolderClick={handleFolderClick}
        hasChildren={hasChildren}
        isSelected={isSelected}
        loading={loading}>
        <FolderContainer>
          <Folder
            node={{ ...(folder as IFolder), ...contents }}
            showChildren={expanded}
            selected={isSelected}
            selectedProjectFolder={selectedProjectFolder}
            selectedProject={activeProject}
          />
          <FolderText selected={isSelected}>{folder.name}</FolderText>
          {loading && <LoadingSpinner width={12} height={12} style={{ display: 'inline-block', marginLeft: '4px' }} />}
        </FolderContainer>
      </FolderNodeDisplay>

      {expanded && (
        <ChildrenWrapper>
          {loading
            ? null
            : children.map((child) => (
                <FolderNode
                  key={child.id}
                  folder={child}
                  folderId={child.id}
                  projectId={projectId}
                  selectedFolderId={selectedFolderId}
                  setSelectedFolderId={setSelectedFolderId}
                  activeProject={activeProject}
                  setActiveProject={setActiveProject}
                  selectedProjectFolder={selectedProjectFolder}
                  onChangeProjectFolder={onChangeProjectFolder}
                />
              ))}
        </ChildrenWrapper>
      )}
    </FolderNodeWrapper>
  );
}

const FolderTree: React.FC<FolderTreeProps> = ({
  projectId,
  onChangeProjectFolder,
  activeProject,
  selectedProjectFolder,
  setActiveProject
}) => {
  const [rootFolders, setRootFolders] = useState<Folder[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    // Implement your logic to get the access token here.
    // For example, you might fetch it from localStorage or make an API call.
    return token;
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchRootFolders = async () => {
      setLoading(true);
      const clientOptions = {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`
        }
      };
      const response = await fetch(`${NEW_API_BASE_URL}/content-v2/${projectId}/folders/root`, clientOptions);
      const data: IFolderRequestResponse<RootFolder> = await response.json();
      const { folders } = normalizeResponseData(data);
      setRootFolders(folders);
      setLoading(false);
    };
    fetchRootFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (activeProject) {
      setActiveProject(activeProject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, activeProject]);

  return loading ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <FolderTreeWrapper>
      {rootFolders.map((folder) => (
        <FolderNode
          key={folder.id}
          folder={folder}
          folderId={folder.id}
          projectId={projectId}
          selectedFolderId={selectedFolderId}
          activeProject={activeProject}
          setSelectedFolderId={setSelectedFolderId}
          setActiveProject={setActiveProject}
          selectedProjectFolder={selectedProjectFolder}
          onChangeProjectFolder={onChangeProjectFolder}
        />
      ))}
    </FolderTreeWrapper>
  );
};

export default FolderTree;
