/* eslint-disable react/display-name */
import React from 'react';
import type { FlexBaseProps, FlexButtonProps } from '../../../components/FlexButton';
import { FlexButton, FlexButtonState, FlexButtonStateWrapper, FlexControlTypes } from '../../../components/FlexButton';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import type { IDevice } from '../../DeviceBrowser/types';

import type { IFolder } from '../TreeNode';
import TreeNode from '../TreeNode';
import type { ConfirmHandlerType, IOnConfirmProps, OnSelectHandlerType } from '../types';
import type { IProject } from './newApi/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUseProjectFolderBrowserProps {
  onConfirm?: ((props: ConfirmHandlerType | IFolder | IOnConfirmProps) => void) | ((props: IOnConfirmProps) => void);
  onSelect?: (props: OnSelectHandlerType) => void;
}

export const useBrowser = ({ onConfirm, onSelect }: IUseProjectFolderBrowserProps) => {
  const [selectedProjectFolder, setSelectedProjectFolder] = React.useState<IFolder | null>(null);
  const [selectedProject, setActiveProject] = React.useState<IProject>();
  const [isPending, setIsPending] = React.useState<boolean>(false);

  const handleSelectProjectFolder = React.useCallback((spf: IFolder | null) => {
    setSelectedProjectFolder(spf);
  }, []);

  const handleSelectProject = React.useCallback((ap: IProject) => setActiveProject(ap), []);

  React.useEffect(() => {
    if (selectedProject !== undefined && selectedProjectFolder !== null) {
      onSelect && onSelect({ project: selectedProject, projectFolder: selectedProjectFolder });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, selectedProjectFolder]);

  const ConfirmButton = React.useMemo(
    () => (props: unknown) => {
      const handleIsDisabled = () => {
        // new pix does not support these permissions yet
        if (selectedProject === undefined || selectedProjectFolder === null) {
          return true;
        }
        return false;
      };

      return (
        <FlexButtonStateWrapper state={handleIsDisabled() ? FlexButtonState.disabled : FlexButtonState.normal}>
          <FlexButton
            {...(props as FlexBaseProps & FlexButtonProps)}
            text={isPending ? undefined : 'Confirm'}
            style={{ flex: '0 1 35px' }}
            state={handleIsDisabled() ? FlexButtonState.disabled : FlexButtonState.normal}
            span={3}
            type={FlexControlTypes.moreToolbarItem}
            onClick={async () => {
              if (onConfirm !== undefined) {
                setIsPending(true);
                await onConfirm({
                  project: selectedProject as IProject,
                  projectFolder: selectedProjectFolder as IFolder
                });
                setIsPending(false);
              }
            }}>
            {isPending && <LoadingSpinner width={8} height={8} />}
          </FlexButton>
        </FlexButtonStateWrapper>
      );
    },
    [isPending, onConfirm, selectedProject, selectedProjectFolder]
  );

  const Browser = React.useMemo(
    () =>
      ({ activeProject, device }: { rootFolderId: string; activeProject?: IProject; device: IDevice }) => {
        return (
          <TreeNode
            projectId={activeProject?.id || ''}
            selectedProjectFolder={device}
            activeProject={activeProject}
            onChangeProjectFolder={handleSelectProjectFolder}
            setActiveProject={handleSelectProject}
          />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps, prettier/prettier
    }, []);
  return [Browser, ConfirmButton] as const;
};
