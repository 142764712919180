/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This module provides functionality for persisting session data for
 * interaction with Core.
 */
import { PixHeaders, LocalStorageKeys } from '../api/constants';

const USER = LocalStorageKeys.User;
const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
const PROJECTS_LIST = 'PROJECTS_LIST';
const SIGNEDIN_USERS = 'SIGNEDIN_USERS';

const SESSION_ID = LocalStorageKeys.SessionId;
const ACCESS_TOKEN = LocalStorageKeys.AccessToken;
const REFRESH_TOKEN = LocalStorageKeys.RefreshToken;
const USER_ID = PixHeaders.UserId;
const DOMAIN_ID = PixHeaders.DomainId;
const SESSION_JWT = PixHeaders.SessionJWT;

export interface OrganisationItem {
  id: string;
  title: string;
  updated: string;
  created: string;
  archived: boolean;
}

export enum EOrganisationStatus {
  Active = 'active',
  Available = 'available',
  Expired = 'expired',
  Disconnected = 'disconnected'
}

export type TOrganisation = OrganisationItem & {
  image: string;
  status: EOrganisationStatus;
};

export interface SignedInUser {
  accessToken: string;
  refreshToken: string;
  logged: boolean;
  isAccountActive: boolean;
  user: ActiveUser;
  organizations: TOrganisation[];
}

/**
 * Represents the active user for display purposes.
 */
export interface ActiveUser {
  id: string;
  name: string;
  thumbnailUrl: string;
  details?: UserDetails;
}

export interface UserDetails {
  avatar?: string;
  firstName?: string;
  id: string;
  jobDescription?: string;
  lastName?: string;
  email?: string;
  acceptedTnCVersion?: string;
}

function getValueFromLocalStorage(key: string) {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const storedValue = localStorage.getItem(key) || 'null';
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    // console.error(error);
  }

  return storedValue;
}

function saveValueToLocalStorage(key: string, value: any) {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Clears the currently stored session id, if set.
 */
function clearPersistedSessionId() {
  localStorage.removeItem(SESSION_ID);
}

function clearPersistedTokens() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

/**
 * Stores an access token.
 *
 * @param accessToken The access token to be stored
 */
function persistAccessToken(accessToken: string) {
  saveValueToLocalStorage(ACCESS_TOKEN, accessToken);
}

/**
 * Stores a refresh token.
 *
 * @param refreshToken The refresh token to be stored
 */
function persistRefreshToken(refreshToken: string | null) {
  saveValueToLocalStorage(REFRESH_TOKEN, refreshToken);
}

/**
 * Stores a session id for interaction with Core.
 *
 * @param sessionId The session id to be stored
 */
function persistSessionId(sessionId: string) {
  saveValueToLocalStorage(SESSION_ID, sessionId);
}

/**
 * Gets the stored access token, if set.
 */
function retrieveAccessToken() {
  return getValueFromLocalStorage(ACCESS_TOKEN) ?? '';
}

/**
 * Gets the stored refresh token, if set.
 */
function retrieveRefreshToken() {
  return getValueFromLocalStorage(REFRESH_TOKEN) ?? '';
}

/**
 * Gets the stored session id, if set.
 */
function retrieveSessionId() {
  return getValueFromLocalStorage(SESSION_ID) ?? '';
}

/**
 * Clears the currently stored user id, if set.
 */
function clearPersistedUserId() {
  localStorage.removeItem(USER_ID);
}

/**
 * Stores a user id.
 *
 * @param userId The user id to be stored
 */
function persistUserId(userId: string) {
  saveValueToLocalStorage(USER_ID, userId);
}

/**
 * Gets the stored user id, if set.
 */
function retrieveUserId() {
  return getValueFromLocalStorage(USER_ID);
}

/**
 * Clears the currently stored user id, if set.
 */
function clearPersistedDomainId() {
  localStorage.removeItem(DOMAIN_ID);
}

/**
 * Stores a domain id.
 *
 * @param domainId The domain id to be stored
 */
function persistDomainId(domainId: string) {
  saveValueToLocalStorage(DOMAIN_ID, domainId);
}

/**
 * Gets the stored user id, if set.
 */
function retrieveDomainId() {
  return getValueFromLocalStorage(DOMAIN_ID);
}

/**
 * Get user from local storage
 */
function retrieveUser() {
  const user = getValueFromLocalStorage(USER);
  return user || null;
}

/**
 * Get active user from local storage
 */
function retrieveSignedInUsers() {
  const signedInUsers = getValueFromLocalStorage(SIGNEDIN_USERS);
  return signedInUsers || [];
}

/**
 * Set user to local storage
 */
function persistUser(user: any) {
  saveValueToLocalStorage(USER, user);
}

/**
 * Check if user already exists in list of additional users
 */
function checkUserIsLoggedIn(userId: string) {
  const signedInUsers = retrieveSignedInUsers();
  if (signedInUsers && signedInUsers[0].user) {
    saveValueToLocalStorage(
      SIGNEDIN_USERS,
      signedInUsers.filter((user: SignedInUser) => user.user.id !== userId)
    );
  }
}

/**
 * Remove user from local storage
 */
function removeUser() {
  localStorage.removeItem(USER);
}

/**
 * Get active project from local storage
 */
function retrieveActiveProject() {
  const activeProject = getValueFromLocalStorage(ACTIVE_PROJECT);
  return activeProject || null;
}

/**
 * Get projects list from local storage
 */
function retrieveProjectsList() {
  const projectsList = getValueFromLocalStorage(PROJECTS_LIST);
  return projectsList || null;
}

/**
 * Set active project to local storage
 */
function setActiveProject(activeProject: any) {
  saveValueToLocalStorage(ACTIVE_PROJECT, activeProject);
}

/**
 * Remove active project from local storage
 */
function removeActiveProject() {
  localStorage.removeItem(ACTIVE_PROJECT);
}

/**
 * Clears the currently stored Session JWT, if set.
 */
function clearPersistedSessionJWT() {
  localStorage.removeItem(SESSION_JWT);
}

/**
 * Stores a session JWT.
 *
 * @param domainId The domain id to be stored
 */
function persistSessionJWT(jwt: string) {
  saveValueToLocalStorage(SESSION_JWT, jwt);
}

/**
 * Gets the stored Session JWT, if set.
 */
function retrieveSessionJWT() {
  return getValueFromLocalStorage(SESSION_JWT);
}

export {
  clearPersistedSessionId,
  persistSessionId,
  retrieveSessionId,
  retrieveUser,
  retrieveSignedInUsers,
  persistUser,
  setActiveProject,
  retrieveActiveProject,
  retrieveProjectsList,
  clearPersistedUserId,
  persistUserId,
  retrieveUserId,
  clearPersistedDomainId,
  persistDomainId,
  retrieveDomainId,
  removeUser,
  removeActiveProject,
  clearPersistedSessionJWT,
  persistSessionJWT,
  retrieveSessionJWT,
  persistAccessToken,
  persistRefreshToken,
  retrieveAccessToken,
  retrieveRefreshToken,
  clearPersistedTokens,
  checkUserIsLoggedIn
};
