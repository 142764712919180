import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <path d="m 60.6875,9.71875 c -3.077848,0 -5.5625,2.484652 -5.5625,5.5625 l 0,39.90625 c 0,3.077848 2.484652,5.5625 5.5625,5.5625 l 5.96875,0 c 3.077848,0 5.53125,-2.484652 5.53125,-5.5625 l 0,-39.90625 c 0,-3.077848 -2.453402,-5.5625 -5.53125,-5.5625 l -5.96875,0 z m 16.1875,17.09375 0,16.4375 c 10.78771,4.907818 18.28125,15.780628 18.28125,28.40625 0,17.220933 -13.9353,31.15625 -31.15625,31.15625 -17.22093,0 -31.21875,-13.935317 -31.21875,-31.15625 0,-12.251424 7.09215,-22.835195 17.375,-27.9375 l 0,-16.65625 c -19.0217,5.891417 -32.8125,23.638062 -32.8125,44.59375 0,25.766671 20.88959,46.625 46.65625,46.625 25.76667,0 46.65625,-20.858329 46.65625,-46.625 0,-21.298684 -14.2775,-39.252235 -33.78125,-44.84375 z" />
      </svg>
    </SvgIcon>
  );
}
