import type { ITooltipProps } from 'features/DeviceManager/features/DeviceBrowser/types';
import { Tooltip as BaseTooltip, Fade } from '@mui/material';
import React from 'react';

export const Tooltip = ({ children, title, shouldWrap, ...rest }: ITooltipProps) => {
  return (
    <BaseTooltip
      title={title}
      {...rest}
      placement="right-start"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={1650}
      enterNextDelay={1250}
      arrow>
      {shouldWrap !== undefined && shouldWrap === true ? (
        <div style={{ display: 'flex' }}> {children} </div>
      ) : (
        (children as React.ReactElement)
      )}
    </BaseTooltip>
  );
};
