import styled, { keyframes } from 'styled-components';
import { grey, blueGrey } from '@mui/material/colors';

export const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ISpinnerProps {
  [x: string]: unknown;
  width: number;
  height: number;
}
export const Spinner = styled.div<ISpinnerProps>`
  width: ${(props) => (props.width !== undefined ? props.width : 80)}px;
  height: ${(props) => (props.height !== undefined ? props.height : 80)}px;
  border: 2px solid ${grey['800']};
  border-top-color: ${blueGrey['200']};
  border-radius: 50%;
  animation: ${rotation} 1.2s linear infinite;
`;

function LoadingSpinner({ ...props }) {
  return <Spinner data-testid="spinner" {...props} />;
}

export default LoadingSpinner;
