import type { Column, CompactTableProps } from '@table-library/react-table-library/compact';
import { CompactTable } from '@table-library/react-table-library/compact';
import type { Data, TableNode } from '@table-library/react-table-library';
import type { Theme } from '@table-library/react-table-library/theme';
import { useTheme } from '@table-library/react-table-library/theme';

const darkTheme = {
  row: {
    odd: '#1f1f1f',
    even: `#222222`,
    fontSize: `14px`,
    fontWeight: `400`,
    color: `rgb(196 196 196 / 80%)`
  },
  header: {
    background: `#1F1F1F`,
    border: {
      top: `#080808`,
      bottom: `#080808`
    },
    color: `#e0e0e0`,
    fontSize: `12px`
  }
};
const THEME = {
  Table: ``,
  Header: ``,
  Body: ``,
  BaseRow: `
      background-color: var(--theme-ui-colors-background);

      &.row-select-selected, &.row-select-single-selected {
        background-color: var(--theme-ui-colors-background-secondary);
        color: var(--theme-ui-colors-text);
      }
    `,
  HeaderRow: `
      font-size:  ${darkTheme.header.fontSize};
      color:  ${darkTheme.header.color};

      .th {
        // border-top: 1px solid ${darkTheme.header.border.top};
        // border-bottom: 1px solid ${darkTheme.header.border.bottom};
      }
      background-color: ${darkTheme.header.background};
    `,
  Row: `
      font-size: ${darkTheme.row.fontSize};
      font-weight: ${darkTheme.row.fontWeight};
      color: ${darkTheme.row.color};

      // color: var(--theme-ui-colors-text);

      &:not(:last-of-type) .td {
        border-bottom: 1px solid var(--theme-ui-colors-border);
      }
      // &:hover {
      //   color: var(--theme-ui-colors-text-light);
      // }
      // &:hover {
      //   color: var(--theme-ui-colors-text-light);
      // }
      &:nth-of-type(odd) {
        background-color: ${darkTheme.row.odd};
      }

      &:nth-of-type(even) {
        background-color: ${darkTheme.row.even};
      }
    `,
  BaseCell: `

        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
        min-width: calc(2 * 32px);
        height: calc(4 * 16px);

        padding: 8px;

        // svg {
        //   margin-top: 6px;
        // }
      `,
  HeaderCell: `
      svg {
        fill: black;
        margin-top: 6px;
      }
  `,
  Cell: `
      display: flex;
      flex-flow: row nowrap;
      width:  100%;
    `
};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUseTableProps<Type extends TableNode> extends CompactTableProps<Type> {
  columns: Column<Type>[];
  data: Data<Type>;
  theme?:
    | {
        [x: string]: string;
      }
    | Theme;
  [x: string]: unknown;
}

export function useTable<Type extends TableNode>({ columns, data, theme, ...rest }: IUseTableProps<Type>) {
  const baseTheme = useTheme({ ...THEME, ...theme });

  return [
    (props: Partial<CompactTableProps<Type>>) => (
      <CompactTable columns={columns} data={data} theme={baseTheme} {...rest} {...props} />
    )
  ];
}
