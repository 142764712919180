/* eslint-disable camelcase */
import type { IDevicePayload } from '../features/DeviceBrowser/hooks/useAssociateDevice';
import type { IDevice } from '../features/DeviceBrowser/types';
import BASE_URL from '../utils/deviceManagerConfig';

// MARK: Apparantly this endpoint has not been implemented yet
export const getDevices = async (accessToken: string) => {
  const response = await fetch(`${BASE_URL}/devices`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      throw new Error('Authorization error');
    }

    throw new Error('Failed to fetch device list.');
  }

  const result = await response.json();
  return result;
};

export const associateDevice = async (accessToken: string, id: string, payload: IDevicePayload) => {
  const response = await fetch(`${BASE_URL}/devices/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'User-Agent': `${payload.brand}/${payload.version} ${payload.model}`
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      throw new Error('Authorization error');
    }
    throw new Error('Failed to associate device.');
  }

  const result = await response.json();
  return result;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IRequiredPayload extends Partial<IDevice> {
  type: string;
  hostname: string;
}

export const createDevice = async (accessToken: string, id: string, payload: IRequiredPayload) => {
  const response = await fetch(`${BASE_URL}/devices/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      throw new Error('Authorization error');
    }

    throw new Error('Failed to create device.');
  }

  const result = await response.json();
  return result;
};

export const deleteDevice = async (accessToken: string, id: string) => {
  const response = await fetch(`${BASE_URL}/devices/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      throw new Error('Authorization error');
    }

    throw new Error('Failed to delete device.');
  }

  const result = await response.json();
  return result;
};
