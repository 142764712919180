/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

const ACCESS_TOKEN_KEY = 'dm-x-pix-access-token';
const ACCESS_TOKEN_EXP_KEY = 'dm-x-pix-access-token-exp';

interface FetchOptions {
  headers?: HeadersInit;
  body?: BodyInit;
}

export interface FetchClientOptions {
  getAccessToken: () => Promise<string>;
}

interface ResponseData<T> {
  data: T;
  expired: boolean;
}

type TriggerFn = (url: string, options?: FetchOptions) => void;

export type UseFetchResult<T> = [
  ResponseData<T> | null,
  boolean,
  Error | null,
  TriggerFn | (() => void) | (() => Promise<void>)
];

const useFetch = <T>(clientOptions: FetchClientOptions): UseFetchResult<T> => {
  const [data, setData] = React.useState<ResponseData<T> | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [url, setUrl] = React.useState<string>('');
  const [options, setOptions] = React.useState<FetchOptions>({});
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const fetchWithToken = async (accessToken: string) => {
    setLoading(true);

    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        // const { data, ...json } = await response.json();
        const data = await response.json();
        setData({
          data: data,
          expired: false
        });
      } else if (response.status === 401) {
        const newAccessToken = await clientOptions.getAccessToken();
        if (newAccessToken) {
          fetchWithToken(newAccessToken);
        } else {
          setError(new Error('Unable to refresh access token.'));
        }
      } else {
        setError(new Error(`Request failed with status ${response.status}`));
      }
    } catch (e) {
      setError(new Error('Unable to fetch data.'));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    const accessToken = await clientOptions.getAccessToken();

    if (!accessToken) {
      setError(new Error('Unable to get access token.'));
      return;
    }

    const tokenExpireTime = new Date(localStorage.getItem(`${ACCESS_TOKEN_EXP_KEY}`) as string);
    const currentTime = new Date();
    const expired = currentTime.getTime() >= tokenExpireTime.getTime();

    if (expired) {
      const newAccessToken = await clientOptions.getAccessToken();
      if (!newAccessToken) {
        setError(new Error('Unable to refresh access token.'));
        return;
      }

      localStorage.setItem(`${ACCESS_TOKEN_KEY}`, newAccessToken);
      const newTokenExpireTime = new Date(currentTime.getTime() + 3600000);
      localStorage.setItem(`${ACCESS_TOKEN_EXP_KEY}`, newTokenExpireTime.toString());
      fetchWithToken(newAccessToken);
    } else {
      fetchWithToken(accessToken);
    }
  };

  React.useEffect(() => {
    if (url) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, trigger]);

  function triggerFetch(url: string, options?: FetchOptions): void;
  function triggerFetch(url: string, options?: FetchOptions): Promise<void>;
  async function triggerFetch(fetchUrl: string, fetchOptions?: FetchOptions) {
    setUrl(fetchUrl);
    setOptions(fetchOptions || {});
    setTrigger(!trigger);
  }

  return [data, loading, error, triggerFetch];
};

export default useFetch;
