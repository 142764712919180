/* eslint-disable @typescript-eslint/naming-convention */
import styled from 'styled-components/macro';

export enum FlexControlTypes {
  normal,
  button,
  icon,
  inputAccessory,
  headerToolbarItem,
  moreToolbarItem,
  accessoryToolbarItem
}

export interface FlexBaseProps {
  span?: number;
  vspan?: number;
  type?: FlexControlTypes;
  state?: FlexButtonState;
}

export enum FlexButtonState {
  normal,
  selected,
  disabled
}

export interface FlexButtonProps extends FlexBaseProps {
  icon?: string;
  hoverIcon?: string;
  text?: string;

  position?: string;
}

const handleControlTypeBackgroundColor = (type: FlexControlTypes) => {
  switch (type) {
    case FlexControlTypes.inputAccessory:
      return '#1f1f1f';

    case FlexControlTypes.headerToolbarItem:
      return '#2f2f2f';

    case FlexControlTypes.moreToolbarItem:
      return 'none';

    case FlexControlTypes.icon:
      return 'none';

    default:
      return '#0f0f0f';
  }
};

const handleHighlightColorForType = (type: FlexControlTypes) => {
  switch (type) {
    case FlexControlTypes.inputAccessory:
      return '#2f2f2f';

    case FlexControlTypes.headerToolbarItem:
      return '#3f3f3f';

    case FlexControlTypes.moreToolbarItem:
      return 'hsla(0, 0%, 23.52941176470588%, 0.271)';
    case FlexControlTypes.icon:
      return 'none';

    default:
      return '#1f1f1f';
  }
};

// const handleButtonStateBackgroundColor = (state: FlexButtonState) => {
//     switch (state) {
//         case FlexButtonState.selected:
//             return '#3F3F3F';
//         default:
//             return '#0F0F0F';
//     }
// };

export const FlexSpacer = styled.div`
  flex: 1;
  background: transparent;
`;

export const FlexBlock = styled.div<FlexBaseProps>`
  display: flex;
  width: ${(props) => (props.span === undefined ? '32px;' : `calc(32px * ${props.span})`)};
  height: ${(props) => (props.vspan === undefined ? '32px;' : `calc(32px * ${props.vspan})`)};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${(props) => (props.type !== undefined ? handleControlTypeBackgroundColor(props.type) : '#0f0f0f')};
  color: #9f9f9f;
  font-size: 12pt;
  font-family: 'inter-regular', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 0.75vw;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
`;
export const FlexTextBlock = styled(FlexBlock)<FlexButtonProps>`
  display: flex;
  width: ${(props) => (props.span === undefined ? '32px;' : `calc(32px * ${props.span})`)};
  height: ${(props) => (props.vspan === undefined ? '32px;' : `calc(32px * ${props.vspan})`)};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${(props) => (props.type !== undefined ? handleControlTypeBackgroundColor(props.type) : '#0f0f0f')};
  border-radius: 2px;
  color: #9f9f9f;
  font-size: 12pt;
  font-family: 'inter-regular', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 0.75vw;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  ${(props) => (props.text !== undefined || props.icon !== undefined ? `content: '${props.text ?? ' '}';` : ``)}

  ${(props) =>
    props.icon !== undefined
      ? `background-image: url(${process.env.PUBLIC_URL}/${props.icon});
            background-position: 50% 50%;
            background-size: 22px;
            background-repeat: no-repeat;
            -webkit-filter: opacity(50%);
            filter: opacity(50%);
            `
      : ``}
`;

interface FlexIconProps extends FlexButtonProps {
  flip?: boolean;
}

export const FlexIcon = styled(FlexBlock)<FlexIconProps>`
  cursor: pointer;

  ${(props) =>
    props.state === FlexButtonState.selected
      ? `background: none;
            color: #ffffff;`
      : ``}

  ::before {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    ${(props) => (props.text !== undefined || props.icon !== undefined ? `content: '${props.text ?? ' '}';` : ``)}
    ${(props) => (props.flip !== undefined || props.flip === true ? `transform: scaleY(-1);` : ``)}

    ${(props) =>
      props.icon !== undefined
        ? `background-image: url(${process.env.PUBLIC_URL}/${props.icon});
            background-position: 50% 50%;
            background-size: 22px;
            background-repeat: no-repeat;
            -webkit-filter: opacity(50%);
            filter: opacity(50%);
            `
        : ``}
  }
`;
export const FlexButtonStateWrapper = styled.div<FlexBaseProps>`
  ${(props) =>
    props.state !== undefined && props.state === FlexButtonState.disabled ? `cursor: not-allowed;` : `cursor: pointer;`}
`;

export const FlexButton = styled(FlexBlock)<FlexButtonProps>`
  cursor: pointer;

  ${(props) =>
    props.state !== undefined && props.state === FlexButtonState.disabled
      ? ` pointer-events: none;`
      : `:hover {
                background-color: ${props.type !== undefined ? handleHighlightColorForType(props.type) : '#2f2f2f'};
                ::before {
                    color: #ffffff;
                    -webkit-filter: opacity(100%);
                    filter: opacity(100%);
                }
      }`}

  ${(props) =>
    props.state === FlexButtonState.selected
      ? `background: #3f3f3f;
            color: #ffffff;`
      : ``}

  ::before {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    ${(props) => (props.text !== undefined || props.icon !== undefined ? `content: '${props.text ?? ' '}';` : ``)}

    ${(props) =>
      props.icon !== undefined
        ? `background-image: url(${process.env.PUBLIC_URL}/${props.icon});
            background-position: ${props.position ? props.position : '50% 50%'};
            background-size: 22px;
            background-repeat: no-repeat;
            -webkit-filter: opacity(50%);
            filter: opacity(50%);
            `
        : ``}
  }

  ${(props) =>
    props.state !== FlexButtonState.selected || props.hoverIcon !== undefined
      ? `:hover {
        background-color: ${props.type !== undefined ? handleHighlightColorForType(props.type) : '#2f2f2f'};
        ::before {
          color: #ffffff;
          -webkit-filter: opacity(100%);
          filter: opacity(100%);
        }
      }`
      : ``}
  ${(props) =>
    props.hoverIcon !== undefined
      ? `background-image: url(${process.env.PUBLIC_URL}/${props.hoverIcon});
            background-position: ${props.position ? props.position : '50% 50%'};
            background-size: 22px;
            background-repeat: no-repeat;
            -webkit-filter: opacity(50%);
            filter: opacity(50%);
            `
      : ``}
`;
