/* eslint-disable @typescript-eslint/no-explicit-any */

// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
import type { MutableRefObject } from 'react';
import React from 'react';

// hook returns tuple(array) with type [any, boolean]
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = React.useState<boolean>(false);
  const ref: any = React.useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  React.useEffect(
    () => {
      const node: any = ref.current;
      if (node !== null) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
      }
      return () => {
        if (node !== null) {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        }
      };
    },

    //   [ref.current] // Recall only if ref changes
    [] // Recall only if ref changes
  );
  return [ref, value];
}
