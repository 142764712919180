import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Dialog, DialogTitle } from '@mui/material';
import { Spacer } from '../../../components/Spacer';
// import { Spacer } from "components/Spacer";

const SvgSize = css`
  width: 1.8rem;
  height: 1.8rem;
`;

const Container = styled.div`
  display: flex;
  min-height: 3rem;
  align-items: center;
  align-content: center;
  z-index: 10;
  flex: 0 0 auto;
  padding: 0 0.25rem;

  svg {
    ${SvgSize}
  }

  img {
    display: block;
    width: 1.8rem;
    opacity: 0.5;
    /* margin-left: 0.75rem; */

    /* &:hover {
        opacity: 1;
      } */
  }
`;
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IAboutDialogProps {
  buttonComponent: React.ReactNode;
}

export default function AboutDialog({ buttonComponent: Component }: IAboutDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{Component}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialogTitle-root': {
            fontFamily: `'inter-regular','Helvetica Neue',sans-serif`,
            fontSize: `0.95rem`,
            fontWeight: 300
          },
          '& .MuiDialog-paper': {
            backgroundColor: `#212121`,
            color: `#9e9e9eb3`
          },
          '& .MuiDialogContentText-root': {
            color: `#616161`
          }
        }}>
        <DialogTitle id="alert-dialog-title">
          <Container>
            <Spacer span={2} />
            {Component}
            <Spacer span={0.2} />
            DEVICE MANAGER v1.0.0
            <Spacer span={2} />
          </Container>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
