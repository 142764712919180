import { Navigate, Route, Routes } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import DeviceManager from './features/DeviceManager/DeviceManager';
import Header from 'components/Header';
import styled from 'styled-components/macro';

// This code uses React Router's Routes and Route components to define the application's routes. It also uses the
//     Auth0 withAuthenticationRequired HOC to handle authentication and redirection to the home page.

// The Protected component is created by wrapping a styled div with the withAuthenticationRequired HOC.
// The component handles redirects to the login page if the user is not logged in.
const Wrapper = styled.div``;
const Protected = withAuthenticationRequired(Wrapper);

// The routes are defined with the base path of '/' and include a home subroute that will render
// the Header and DeviceManager components.
// The routes also handle any invalid routes and redirect them to the home route.
export const routes = (
  <Routes>
    <Route
      path="home"
      element={
        <Protected>
          <Header />
          <DeviceManager />
        </Protected>
      }
      // The hasErrorBoundary prop adds an ErrorBoundary component to this route.
      hasErrorBoundary
    />
    <Route // This route catches any invalid routes and redirects the user to the home route.
      path="*"
      element={<Navigate to="home" />}
      hasErrorBoundary
    />
  </Routes>
);
