import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
// import { ApplicationButton, ApplicationButtonIcon } from 'features/Header/components/Button';

export function SortUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" id="icon-bound" fill="none" />
        <path d="M11.292,8.878L11.139,9h0.275L11.292,8.878z M12.164,9.75h-1.966L9.886,10h2.531L12.164,9.75z M11.664,9.25h-0.837 L10.514,9.5h1.403L11.664,9.25z M8.627,11h3.181l0.319-0.25H8.939L8.627,11z M7.998,14l0.319-0.25H7.683L7.998,14z M6.73,13H9.27 l0.319-0.25H6.411L6.73,13z M6.095,12.5h3.809l0.319-0.25H5.777L6.095,12.5z M7.364,13.5h1.269l0.319-0.25H7.048L7.364,13.5z M5.458,12h5.078l0.319-0.25H5.142L5.458,12z M4.508,11.25l0.319,0.25h3.172l-0.312-0.25H4.508z M9.567,10.25L9.255,10.5h3.188 l0.262-0.206l-0.044-0.044H9.567z M7.998,11.5h3.175l0.319-0.25H8.311L7.998,11.5z M4.705,8.878L4.583,9h0.275L4.705,8.878z M5.173,9.25H4.336L4.086,9.5h1.403L5.173,9.25z M7.058,10.75H3.87L4.189,11H7.37L7.058,10.75z M6.43,10.25H3.336l-0.044,0.044 L3.555,10.5h3.188L6.43,10.25z M5.802,9.75H3.836L3.586,10h2.531L5.802,9.75z M7.998,4.5l3.294,2.622l1.416-1.416L7.998,2 L3.292,5.706l1.416,1.416L7.998,4.5z" />
      </svg>
    </SvgIcon>
  );
}

export function SortDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" id="icon-bound" fill="none" />
        <path d="M9.27,3H6.73L6.411,3.25h3.175L9.27,3z M7.373,5H4.192L3.873,5.25h3.184L7.373,5z M8.636,2.5H7.367L7.048,2.75h1.903 L8.636,2.5z M10.542,4H5.461L5.142,4.25h5.712L10.542,4z M9.905,3.5H6.098L5.78,3.75h4.444L9.905,3.5z M8.002,2L7.683,2.25h0.634 L8.002,2z M11.492,4.75L11.173,4.5H8.002l0.312,0.25H11.492z M8.002,4.5H4.827L4.508,4.75h3.181L8.002,4.5z M9.57,5.75h3.094 l0.044-0.044L12.445,5.5H9.258L9.57,5.75z M8.942,5.25h3.184L11.808,5H8.63L8.942,5.25z M11.917,6.5h-1.403l0.312,0.25h0.837 L11.917,6.5z M11.142,7l0.153,0.122L11.417,7H11.142z M6.433,5.75L6.745,5.5H3.558L3.295,5.706L3.339,5.75H6.433z M12.417,6H9.886 l0.312,0.25h1.966L12.417,6z M5.489,6.5H4.086l0.25,0.25h0.838L5.489,6.5z M4.586,7l0.122,0.122L4.861,7H4.586z M6.117,6H3.586 l0.25,0.25h1.966L6.117,6z M8.002,11.5L4.708,8.878l-1.416,1.416L8.002,14l4.706-3.706l-1.416-1.416L8.002,11.5z" />
      </svg>
    </SvgIcon>
  );
}

export function SortIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" id="icon-bound" fill="none" />
        <path
          stroke="none"
          fill="#7d7d7d"
          d="M9.269,3H6.728L6.409,3.25h3.175L9.269,3z M7.372,5H4.191L3.872,5.25h3.184L7.372,5z M8.634,2.5H7.366L7.047,2.75H8.95 L8.634,2.5z M10.541,4H5.459L5.141,4.25h5.712L10.541,4z M9.903,3.5H6.097L5.778,3.75h4.444L9.903,3.5z M8,2L7.681,2.25h0.634L8,2z M11.491,4.75L11.172,4.5H8l0.312,0.25H11.491z M8,4.5H4.825L4.506,4.75h3.181L8,4.5z M9.569,5.75h3.094l0.044-0.044L12.444,5.5 H9.256L9.569,5.75z M8.941,5.25h3.184L11.806,5H8.628L8.941,5.25z M11.916,6.5h-1.403l0.312,0.25h0.837L11.916,6.5z M11.141,7 l0.153,0.122L11.416,7H11.141z M6.431,5.75L6.744,5.5H3.556L3.294,5.706L3.338,5.75H6.431z M11.294,8.878L11.141,9h0.275 L11.294,8.878z M12.166,9.75H10.2L9.887,10h2.531L12.166,9.75z M11.666,9.25h-0.837L10.516,9.5h1.403L11.666,9.25z M12.416,6H9.884 l0.312,0.25h1.966L12.416,6z M8.628,11h3.181l0.319-0.25H8.941L8.628,11z M8,14l0.319-0.25H7.684L8,14z M6.731,13h2.541l0.319-0.25 H6.412L6.731,13z M6.097,12.5h3.809l0.319-0.25H5.778L6.097,12.5z M7.366,13.5h1.269l0.319-0.25H7.05L7.366,13.5z M5.459,12h5.078 l0.319-0.25H5.144L5.459,12z M4.509,11.25l0.319,0.25H8l-0.312-0.25H4.509z M9.569,10.25L9.256,10.5h3.188l0.262-0.206l-0.044-0.044 H9.569z M8,11.5h3.175l0.319-0.25H8.312L8,11.5z M4.706,8.878L4.584,9h0.275L4.706,8.878z M5.175,9.25H4.338L4.088,9.5h1.403 L5.175,9.25z M5.488,6.5H4.084l0.25,0.25h0.838L5.488,6.5z M4.584,7l0.122,0.122L4.859,7H4.584z M7.059,10.75H3.872L4.191,11h3.181 L7.059,10.75z M6.116,6H3.584l0.25,0.25H5.8L6.116,6z M6.431,10.25H3.338l-0.044,0.044L3.556,10.5h3.188L6.431,10.25z M5.803,9.75 H3.837L3.587,10h2.531L5.803,9.75z"
        />
      </svg>
    </SvgIcon>
  );
}
