/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { deleteDevice } from '../../../api/deviceApi';
import { useAsync } from '../../../hooks/useAsync';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useHandleAccessTokenExpired } from './useHandleAccessTokenExpired';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUseDeleteDeviceProps {
  id: string;
  immediate?: boolean;
}
export function useDeleteDevice({ id, immediate = false }: IUseDeleteDeviceProps) {
  const { accessToken } = useLocalStorage();
  const navigate = useNavigate();
  const deleteDeviceWithTokenExpired = useHandleAccessTokenExpired(deleteDevice);
  const { getAccessTokenSilently } = useAuth0();
  const request = async () => {
    const token = await getAccessTokenSilently();
    return () => deleteDeviceWithTokenExpired((accessToken as string) || token, id);
  };

  const { execute, status, value, error } = useAsync(request(), immediate);

  React.useEffect(() => {
    if (error !== null) {
      if (error === 'Authorization error') {
        // console.info(`Authorization error`);
        navigate('sign-out');
      } else {
        // console.info(`List devices error: ${error}`);
      }
    }
  }, [error, navigate]);

  return { execute, status, value, error };
}
