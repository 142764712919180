import { Auth0Root } from './AuthProviderRoot';
import { BrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import { GlobalStyle, CSSReset, defaultTheme } from 'components/theme';
// import DeviceBrowser from '@features/DeviceManager/features/DeviceBrowser/DeviceBrowser';
import { ModalProvider } from 'react-modal-hook';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '@mui/material';

function App() {
  const muiTheme = useTheme();
  return (
    <BrowserRouter>
      <Auth0Root>
        <GlobalStyle />
        <CSSReset />
        <ThemeProvider theme={{ ...defaultTheme, ...muiTheme }}>
          <ModalProvider>{routes}</ModalProvider>
        </ThemeProvider>
      </Auth0Root>
    </BrowserRouter>
  );
}

export default App;
