// import { useHistory } from 'react-router';
import { useAccessTokenExpired } from '../../../hooks/useAccessTokenExpired';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHandleAccessTokenExpired<T extends (...args: any) => any>(fn: T) {
  // const navigate = useHistory();
  const { checkIsAccessTokenExpired } = useAccessTokenExpired();

  return function wrapped(...args: Parameters<T>) {
    // wrapping logic
    if (checkIsAccessTokenExpired()) {
      // console.error(`Access token is expired... logging out of app`);
      // navigate.push('sign-out');
    }
    return fn(...(args as Parameters<T>[]));
  };
}
