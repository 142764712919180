import { createGlobalStyle } from 'styled-components';
import type { DefaultThemeFull } from './types';

export const defaultTheme: DefaultThemeFull = {
  colors: {
    grey210: '#CFCFCF',
    grey110: '#8F8F8F',
    grey90: '#5C5C5C',
    grey80: '#4C4C4C',
    grey70: '#3F3F3F',
    grey45: '#2f2f2f',
    grey40: '#2B2B2B',
    grey35: '#3E3E3E',
    grey30: '#1E1E1E',
    grey20: '#0E0E0E',
    primary: '#008DFF',
    danger: '#FF266E',
    defaultBackground: '#0f0f0f',
    icon: {
      ok: '#4caf50',
      warn: '#f57c00',
      error: '#c61a54',
      info: '#5C5C5C'
    },
    text: {
      primary: '#8f8f8f',
      accent: '#c1bebe'
    },
    altHighlight: '#0080FF',
    cyan: '#306161',
    azure: '#3D5266',
    red: '#7F393B',
    red50: '#F3BEAE',
    red100: '#CF8198',
    red150: '#f14e6c',
    red200: '#674a4a',
    red700: '#D0314D',
    red750: '#800000',
    red800: '#BD0A05',
    red900: '#A52116',
    red1000: '#42120D',
    orange: '#614427',
    yellow: '#545426',
    chartreuse: '#3F542A',
    grey17: '#888888',
    grey18: '#909090',
    grey50: '#DADADA',
    grey75: '#606060',
    grey100: '#AFAFAF',
    grey150: '#DFDFDF',
    grey175: '#C4C4C4',
    grey200: '#8F8F8F',
    grey22: '#B0B0B0',
    grey250: '#5F5F5F',
    grey275: '#d4d4d4',
    grey285: '#404040',
    grey300: '#4f4f4f',
    grey325: '#474747',
    grey350: '#7f7f7f',
    grey370: '#505050',
    grey375: '#585858',
    grey400: '#3F3F3F',
    grey425: '#303030',
    grey450: '#2E2E2E',
    grey500: '#2F2F2F',
    grey550: '#202020',
    grey575: '#636363',
    grey600: '#535353',
    grey700: '#282828',
    grey825: '#333333',
    grey850: '#272727',
    grey875: '#282828',
    grey900: '#1F1F1F',
    grey925: '#101010',
    grey950: '#171717',
    grey975: '#181818',
    grey1000: '#0F0F0F',
    white: '#ffffff',
    white200: '#d0d0d0',
    white500: 'rgba(255, 255, 255, 0.5)',
    white800: 'rgba(255, 255, 255, 0.8)',
    black: '#000000',
    black400: 'rgba(0, 0, 0, 0.4)',
    black850: 'rgba(0, 0, 0, 0.85)',
    blue: '#3F3F73',
    blue50: '#3872ab',
    blue100: '#9ABEBC',
    blue200: '#A5CFF6',
    blue250: '#80AAFF',
    blue300: '#7faaff',
    blue350: '#80bfff',
    blue400: '#69f',
    // TODO: replace blue500 with blue600
    blue500: '#004AB2',
    blue600: '#175be6',
    blue650: '#20487F',
    blue700: '#22252b',
    blue750: '#1c2e54',
    blue800: '#3838AA',
    blue850: '#54aaff',
    blue900: '#17151C',
    blue950: '#223866',
    blue1000: '#009AD6',
    violet: '#60407F',
    magenta: '#733973',
    rose: '#7f4060',
    green: '#02d057',
    green25: '#00D058',
    green75: '#06BD03',
    green50: '#ccffcc',
    green100: '#80CEA1',
    green150: '#00D058',
    green200: '#47AF73',
    green250: '#7ACFC0',
    green300: '#398B5C',
    green400: '#304030',
    green450: '#306130',
    green500: '#008000',
    green800: '48, 64, 48', // RGB
    spring: '#2D5943',
    yellow100: '#CBC69B',
    yellow800: '#ffff00',
    orange200: '#FFBF7F',
    cyan700: '#2A474C',
    brown500: '#4D2E2E'
  },
  color: {
    base: '#5F5F5F',
    baseDark: '#1F1F1F',
    baseDarker: '#0F0F0F',
    baseDarkest: '#000000',
    baseLight: '#3F3F3F',
    baseLighter: '#AFAFAF',
    baseLightest: '#FFFFFF'
  },
  fontSizes: {
    xxs: '11px',
    xs: '12px',
    sm: '13px',
    md: '14px',
    lg: '18px',
    xl: '20px',
    xxl: '24px'
  },
  grid: {
    baseHeight: '32px',
    baseWidth: '32px'
  },
  font: {
    size: {
      small: '10px',
      medium: '14px',
      large: '16px'
    },
    weight: {
      bold: '700',
      medium: '500',
      normal: '400',
      light: '300'
    }
  }
};

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 16px;
  }
  body {
    box-sizing: border-box;
    font-family: 'inter-regular', 'Helvetica Neue', sans-serif;
  }

  button, input {
    font-family: 'inter-regular', 'Helvetica Neue', sans-serif;
  }

  body {
    /* background-color: #333; */
    background-color: #262626;
      overscroll-behavior: none;
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #FFFFFF;
      font-weight: 300;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    scrollbar-width: none;
  }

  .MuiSvgIcon-root {
    font-size: 1.125rem !important;
  }

  .MuiSvgIcon-fontSizeLarge {
    font-size: 1.640625rem !important;
  }
`;

export const CSSReset = createGlobalStyle`
    /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

`;

export type Theme = typeof defaultTheme;
