/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IExecuteProps {
  [x: string]: any;
}

type ExecuteArgs = IExecuteProps | undefined | any;

type UseAsyncFunctionType<T> = (args?: ExecuteArgs) => Promise<T>;
// Hook
export const useAsync = <T, E = string>(
  asyncFunction: UseAsyncFunctionType<T> | Promise<UseAsyncFunctionType<T>>,
  immediate = true
) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    async (args: ExecuteArgs = undefined) => {
      setStatus('pending');
      setValue(null);
      setError(null);
      const resolveFunction = await asyncFunction;
      return resolveFunction(args)
        .then((response: any) => {
          setValue(response);
          setStatus('success');
        })
        .catch((err: any) => {
          setError(err.message);
          setStatus('error');
        });
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
};
