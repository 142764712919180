/* eslint-disable @typescript-eslint/no-unused-vars */
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import type { IFolderContent, INode } from '../types';
import type { IDevice } from '../../DeviceBrowser/types';
import type { IFolder } from '../TreeNode';
import type { IProject } from '../hooks/newApi/types';

export function FolderIconOpen(props: SvgIconProps) {
  return <FolderOpenIcon {...props} />;
}
export function FolderIconClosed(props: SvgIconProps) {
  return <FolderIcon {...props} />;
}
export function FolderIconPermission(props: SvgIconProps) {
  return <FolderOffIcon {...props} />;
}
export function FolderIconSelected(props: SvgIconProps) {
  return <DriveFolderUploadIcon {...props} />;
}

function IconFolder(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M1.5 1A1.5 1.5 0 000 2.5v10A1.5 1.5 0 001.5 14h12a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0013.5 3H7.707l-2-2H1.5z"
      />
    </SvgIcon>
  );
}
function IconFolderTick(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 2.5A1.5 1.5 0 011.5 1h4.207l2 2H13.5A1.5 1.5 0 0115 4.5v8a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 010 12.5v-10zm7.024 8.732l3.852-4.403-.752-.658-3.148 3.598-1.622-1.623-.708.708 2.378 2.378z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
function IconFolderNoAccess(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M7.5 6a2.5 2.5 0 00-2.086 3.879L8.88 6.414A2.488 2.488 0 007.5 6zM7.5 11c-.51 0-.983-.152-1.379-.414L9.586 7.12A2.5 2.5 0 017.5 11z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 2.5A1.5 1.5 0 011.5 1h4.207l2 2H13.5A1.5 1.5 0 0115 4.5v8a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 010 12.5v-10zm4 6a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
function IconFolderOpen(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M572.6 270.3l-96 192c-5.4 10.9-16.5 17.7-29.5 17.7H64c-35.35 0-64-28.66-64-64V96c0-35.34 28.65-64 64-64h117.5c16.97 0 33.25 6.742 45.26 18.75L275.9 96H416c35.35 0 64 28.66 64 64v32h-48v-32c0-8.824-7.178-16-16-16H256l-63.2-59.31c-3-3.03-7-4.69-11.3-4.69H64c-8.82 0-16 7.18-16 16v288l71.16-142.3A31.976 31.976 0 01147.8 224H544c23.7 0 39.2 25 28.6 46.3z" />
    </SvgIcon>
  );
}
function IconFolderOpenFilled(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M88.7 223.8L0 375.8V96c0-35.3 28.7-64 64-64h117.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1c5.8-9.9 16.3-15.9 27.7-15.9h400c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2.1-32.1l112-192z" />
    </SvgIcon>
  );
}
function IconFolders(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M4.5 0A1.5 1.5 0 003 1.5v7A1.5 1.5 0 004.5 10h9A1.5 1.5 0 0015 8.5v-5A1.5 1.5 0 0013.5 2H9.707l-2-2H4.5z"
      />
      <path
        fill="currentColor"
        d="M12 11H4.5A2.5 2.5 0 012 8.5V5h-.5A1.5 1.5 0 000 6.5v7A1.5 1.5 0 001.5 15h9a1.5 1.5 0 001.5-1.5V11z"
      />
    </SvgIcon>
  );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IFolderIcon {
  node: IFolder;
  selected: boolean;
  permissions?: IFolderContent['permissions'];
  showChildren: boolean;
  selectedProjectFolder: Partial<IDevice>;
  selectedProject?: IProject;
}

export default function Folder({
  node,
  selected,
  permissions,
  showChildren,
  selectedProjectFolder,
  selectedProject
}: IFolderIcon) {
  const children = node.contents?.folders || [];

  const isSelectedProjectFolder = (): boolean => {
    if (selectedProject && (selectedProjectFolder.project_id as string) !== selectedProject.id) {
      return false;
    }
    return selectedProjectFolder.folder_id === node.id;
  };

  const handleFolderColor = () => {
    if (isSelectedProjectFolder()) {
      return '#9e9e9e';
      // return "#529262"
    }
    return '#5C5C5C';
  };

  if (permissions && permissions.edit === false) {
    return (
      <IconFolderNoAccess style={{ paddingTop: '1px' }} viewBox="0 0 15 15" fontSize="large" htmlColor="#5C5C5C" />
    );
  }

  if (
    showChildren === true &&
    selected === true &&
    permissions &&
    permissions.edit === true &&
    isSelectedProjectFolder() === false
  ) {
    return (
      <IconFolderOpenFilled style={{ paddingTop: '1px' }} viewBox="0 0 576 512" fontSize="large" htmlColor="#9e9e9e" />
    );
  }

  if (showChildren === true && children.length > 0 && selected === false && isSelectedProjectFolder() === false) {
    return (
      <IconFolderOpen
        style={{ paddingTop: '1px' }}
        viewBox="0 0 576 512"
        fontSize="large"
        htmlColor={handleFolderColor()}
      />
    );
  }
  if (isSelectedProjectFolder() === true) {
    return (
      <IconFolderTick
        style={{ paddingTop: '1px' }}
        viewBox="0 0 15 15"
        fontSize="large"
        htmlColor={handleFolderColor()}
      />
    );
  }

  return (
    <IconFolder style={{ paddingTop: '1px' }} viewBox="0 0 15 15" fontSize="large" htmlColor={handleFolderColor()} />
  );
}

Folder.defaultProps = {
  permissions: {
    edit: true
  }
};
