import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { CenteredContainer } from './CenteredContainer';

export function CenteredLoadingSpinner({ ...props }) {
  return (
    <CenteredContainer>
      <LoadingSpinner {...props} />
    </CenteredContainer>
  );
}
