import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { FlexButton, FlexButtonState, FlexControlTypes } from '../../../components/FlexButton';
import type { IDeleteButton } from './DeleteButton';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IAlertProps {
  buttonComponent?: React.FunctionComponent<IDeleteButton>;
  onConfirm?: () => void;
}

const Container = styled.div``;

const DialogButton = styled(FlexButton)`
  :hover {
    background-color: rgba(85, 85, 85, 0.04);
    border-radius: 4px;
  }
`;
export default function AlertDialog({ buttonComponent: ButtonComponent, onConfirm }: IAlertProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    onConfirm && onConfirm();
    setOpen(false);
  };

  return (
    <Container>
      {ButtonComponent && <ButtonComponent onClick={handleClickOpen} />}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: `#212121`,
            color: `#9e9e9eb3`
          },
          '& .MuiDialogContentText-root': {
            color: `#616161`
          }
        }}>
        <DialogTitle id="alert-dialog-title">Unlink device?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unlink device from associated PIX project. The device will be removed from the device manager. This
            operation cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogButton
            text="Cancel"
            type={FlexControlTypes.icon}
            span={4}
            state={FlexButtonState.normal}
            onClick={handleClose}
          />
          <DialogButton
            text="Unlink"
            type={FlexControlTypes.icon}
            span={4}
            state={FlexButtonState.normal}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Dialog>
    </Container>
  );
}
