import styled from 'styled-components/macro';

interface SpacerProps {
  span?: number;
}

export const Spacer = styled.div<SpacerProps>`
  display: flex;
  content: ' ';
  ${(props) => (props.span !== undefined ? `width: calc(32px * ${props.span});` : `flex: 1;`)}
`;
