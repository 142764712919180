import React from 'react';
import moment from 'moment';
import { useLocalstorageState } from './useLocalstorageState';
import { LocalStorageKeys } from '../api/constants';
import type { AccessTokenTTLType, TTLType } from './types';

export const useAccessTokenExpired = () => {
  const [accessTokenExpired, setAccessTokenExpired, clearAccessTokenExpired] = useLocalstorageState<
    AccessTokenTTLType | string
  >(LocalStorageKeys.AccessTokenTTL);

  const setAccessTokenExpiration = React.useCallback(
    (ttl: TTLType): void => {
      // Sets the access token expiration timestamp in local storage
      const expTs = new Date((ttl as number) * 1000);
      const now = moment(expTs);
      setAccessTokenExpired(now.format());
    },
    [setAccessTokenExpired]
  );

  const checkIsAccessTokenExpired = React.useCallback(() => {
    if (accessTokenExpired === null) {
      return false; // return early if no access token expiration is set in local storage
    }

    const now = moment().format();
    const diff = moment(accessTokenExpired, moment.ISO_8601) // get difference in seconds i.e. diff = expiration_timestamp - now_timestamp
      .diff(now, 'seconds');

    if (accessTokenExpired !== null && diff > 0) {
      return false; // access token not expired
    }
    return true; // access token is expired
  }, [accessTokenExpired]);

  return {
    setAccessTokenExpiration,
    checkIsAccessTokenExpired,
    clearAccessTokenExpired
  };
};
