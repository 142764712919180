function getApiUrl() {
  const apiUrl = process.env.REACT_APP_APP_API_URL;
  if (!apiUrl) {
    throw new Error('REACT_APP_APP_API_URL is not defined in the .env file.');
  }
  return apiUrl;
}

export const NEW_API_BASE_URL: string = process.env.NODE_ENV === 'development' ? 'api' : getApiUrl();

export default NEW_API_BASE_URL;
