import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { ApplicationButton, ApplicationButtonIcon } from '../../Header/components/Button';

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1792 1792">
        <path
          fill="white"
          d="M1082.2,896.6l410.2-410c51.5-51.5,51.5-134.6,0-186.1s-134.6-51.5-186.1,0l-410.2,410L486,300.4
                c-51.5-51.5-134.6-51.5-186.1,0s-51.5,134.6,0,186.1l410.2,410l-410.2,410c-51.5,51.5-51.5,134.6,0,186.1
                c51.6,51.5,135,51.5,186.1,0l410.2-410l410.2,410c51.5,51.5,134.6,51.5,186.1,0c51.1-51.5,51.1-134.6-0.5-186.2L1082.2,896.6z"
        />
      </svg>
    </SvgIcon>
  );
}

export function DeleteTrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>72-Trash</title>
        <g fill="white" id="_72-Trash" data-name="72-Trash">
          <path d="M6,8H24v5h2V8h2a1,1,0,0,0,.89-1.45l-1-2A1,1,0,0,0,27,4H20V2a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2V4H3a1,1,0,0,0-.89.55l-1,2A1,1,0,0,0,2,8H4V30a2,2,0,0,0,.47,1.41A2,2,0,0,0,6,32h7V30H6Zm6-6h6V4H12Z" />
          <path d="M16,10a2,2,0,0,0-2,2v4h2Z" />
          <path d="M19,14h2V10a2,2,0,0,0-2,2Z" />
          <path d="M9,28a2,2,0,0,0,2-2V10a2,2,0,0,0-2,2l.06,14H9Z" />
          <path d="M23,16a8,8,0,1,0,8,8A8,8,0,0,0,23,16Zm0,14a6,6,0,1,1,6-6A6,6,0,0,1,23,30Z" />
          <rect x="19" y="23" width="8" height="2" />
        </g>
      </svg>
    </SvgIcon>
  );
}
export function DeleteTrashIconAlt(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 850 850">
        <g transform="translate(300, 180)">
          <path
            d="M242.833,485.677C108.936,485.677,0,376.742,0,242.838C0,108.936,108.936,0,242.833,0
				c133.909,0,242.844,108.936,242.844,242.838C485.677,376.742,376.742,485.677,242.833,485.677z M242.833,74.431
				c-92.861,0-168.403,75.546-168.403,168.407c0,92.862,75.542,168.408,168.403,168.408c92.861,0,168.414-75.546,168.414-168.408
				C411.247,149.977,335.694,74.431,242.833,74.431z"
          />
          <path
            d="M373.283,243.57c0,20.88-16.926,37.807-37.807,37.807H150.198c-20.879,0-37.805-16.926-37.805-37.805
					c0-21.69,17.583-39.273,39.273-39.273h183.81c20.881,0,37.807,16.927,37.807,37.807L373.283,243.57L373.283,243.57z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDeleteButton {
  [x: string]: unknown;
  onClick?: () => void;
}

export function DeleteButton(props: IDeleteButton) {
  const { disabled, opened, active } = props;
  return (
    <ApplicationButton
      style={{
        backgroundColor: 'transparent',
        borderBottom: 'none',
        width: '12px',
        height: '12px'
      }}
      opened={!!opened}
      active={!!active}
      {...props}>
      <ApplicationButtonIcon type="button" disabled={!!disabled}>
        <DeleteTrashIconAlt />
      </ApplicationButtonIcon>
    </ApplicationButton>
  );
}
