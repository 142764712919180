/* eslint-disable camelcase */
import type { Column } from '@table-library/react-table-library/compact';
import type { NamedExoticComponent } from 'react';
import React, { memo } from 'react';

import { useModal } from 'react-modal-hook';

import type { Sort } from '@table-library/react-table-library/sort';
import { SortIconPositions, SortToggleType, useSort } from '@table-library/react-table-library/sort';
import type { Theme } from '@table-library/react-table-library/theme';
import { useTheme } from '@table-library/react-table-library/theme';

import EditPenIcon from '@mui/icons-material/AppRegistrationRounded';
import ApproveEditIcon from '@mui/icons-material/CheckSharp';
import CancelEditIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { FlexBlock, FlexButton, FlexButtonState, FlexControlTypes } from '../../components/FlexButton';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { ModalComponent } from 'components/Modal';
import { Tooltip } from '../../components/Tooltip';
import { useHover } from '../../hooks/useHover';
import { useTable } from '../../hooks/useTable';
import { CenteredLoadingSpinner } from '../ProjectBrowser/components/CenteredLoadingSpinner';
import type { IProject, IProjects } from '../ProjectBrowser/hooks/newApi/types';
import useProjects from '../ProjectBrowser/hooks/newApi/useProjects';
import { useBrowser } from '../ProjectBrowser/hooks/useBrowser';
import type { IFolder } from '../ProjectBrowser/TreeNode';
import AlertDialog from './components/Alert';
import { DeleteButton } from './components/DeleteButton';
import { useFilters } from './components/Filter';
import { LinkButton, LinkedButton } from './components/LinkIcon';
import { SortDownIcon, SortIcon, SortUpIcon } from './components/SortIcons';
import { useAssociateDevice } from './hooks/useAssociateDevice';
import { useDeleteDevice } from './hooks/useDeleteDevice';
import { useListDevices } from './hooks/useListDevices';
import type { IDeleteActionIconButton, IDevice, INameInputFieldProps, IProjectSelectProps } from './types';

const Wrapper = styled.div`
  /* background-color: #1f1f1f; */
  height: calc(100vh - 3rem);
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #1f1f1f;
  ${FlexButton}::before {
    background-position: 30% 50%;
  }
`;

const DeleteActionIconButton = ({ item, refreshDevices }: IDeleteActionIconButton) => {
  const request = useDeleteDevice({
    id: item.id,
    immediate: false
  });

  if (request.status === 'pending') {
    return (
      <div
        style={{
          width: '16px',
          height: '16px'
        }}>
        <LoadingSpinner width={16} height={16} />
      </div>
    );
  }
  return (
    <AlertDialog
      buttonComponent={DeleteButton}
      onConfirm={async () => {
        await request.execute();
        refreshDevices();
      }}
    />
  );
};

const PaneContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ${FlexButton} {
    background: none;
  }
  ${FlexButton} {
    font-weight: 500;
  }

  ${FlexButton}::before {
    padding-left: 24px;
  }

  ${FlexButton}::before {
    justify-content: flex-start;
    background-position: 0% 50%;
    /* background: url(/device-manager/project.svg) #2b2a2a no-repeat 100% 50% */
  }
  ${FlexButton}:hover::before {
    background-position: 0% 50%;
    /* background-image: url(/device-manager/project_active.svg); */
    /* background: url(/device-manager/project_active.svg) #2b2a2a no-repeat 100% 50% */
  }
`;

function ProjectSelect({ item, refreshDevices }: IProjectSelectProps) {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const request = useAssociateDevice({
    id: item.id,
    immediate: false,
    payload: {
      folder_id: '',
      project_id: '',
      brand: '',
      model: '',
      version: ''
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [projectsData, loadingProjects, projectsError, fetchProjects] = useProjects();

  const [showModal, hideModal] = useModal(
    function Browser() {
      const [selected, setSelected] = React.useState<IProject>();
      const [, setActiveProject] = React.useState<IProject>();

      const handleSelectProject = React.useCallback((project: IProject) => {
        setSelected(project);
        setActiveProject(project);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      React.useEffect(() => {
        !projectsData && fetchProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [projectsData]);

      const [ProjectBrowser, ConfirmButton] = useBrowser({
        onConfirm: async ({ project, projectFolder }: { project: IProject; projectFolder: IFolder }) => {
          await request.execute({
            id: item.id,
            payload: {
              project_id: project && project.id,
              folder_id: projectFolder && projectFolder.id,
              folder_name: projectFolder && projectFolder.name,
              project_name: project && project.title,
              brand: item.brand,
              model: item.model,
              version: item.version
            }
          });
          hideModal();
          refreshDevices();
        }
        // onSelect: ({ project, projectFolder }: { project: IProject; projectFolder: IFolder }) => {

        // }
      });

      return (
        <ModalComponent hideModal={hideModal} headerText={`Folder Browser`} width={1066} height={600}>
          <Allotment vertical defaultSizes={[100, 10]}>
            <Allotment.Pane maxSize={600}>
              <Allotment defaultSizes={[50, 200]}>
                <Allotment.Pane snap>
                  <PaneContainer>
                    <FlexBlock
                      span={6}
                      type={FlexControlTypes.inputAccessory}
                      style={{ justifyContent: 'flex-start', background: 'none', paddingLeft: '8px' }}>
                      Projects
                    </FlexBlock>
                    {loadingProjects ? (
                      <CenteredLoadingSpinner />
                    ) : (
                      projectsData &&
                      (projectsData.data as unknown as IProjects).data.map((project: IProject) => (
                        <FlexButton
                          key={project.id}
                          text={
                            project.title.length > 20 ? `${project.title.slice(0, 20).toString()}...` : project.title
                          }
                          type={FlexControlTypes.inputAccessory}
                          span={6}
                          state={
                            selected && selected.id === project.id ? FlexButtonState.selected : FlexButtonState.normal
                          }
                          onClick={() => handleSelectProject(project)}
                        />
                      ))
                    )}
                  </PaneContainer>
                </Allotment.Pane>
                <Allotment.Pane snap>
                  <FlexBlock
                    span={6}
                    type={FlexControlTypes.inputAccessory}
                    style={{ justifyContent: 'flex-start', background: 'none', paddingLeft: '24px' }}>
                    Folders
                  </FlexBlock>
                  <PaneContainer>
                    {loadingProjects ? (
                      <CenteredLoadingSpinner />
                    ) : (
                      !loadingProjects &&
                      selected !== undefined && (
                        <div style={{ paddingLeft: '12px' }}>
                          <ProjectBrowser
                            rootFolderId={selected ? selected.id : ''}
                            activeProject={selected as IProject}
                            device={item}
                          />
                        </div>
                      )
                    )}
                  </PaneContainer>
                </Allotment.Pane>
              </Allotment>
            </Allotment.Pane>
            <Allotment.Pane snap>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  height: '100%'
                }}>
                <div style={{ marginRight: 'auto' }}>
                  <FlexButton
                    text="Cancel"
                    style={{ flex: '0 1 35px' }}
                    span={3}
                    type={FlexControlTypes.moreToolbarItem}
                    onClick={() => {
                      hideModal();
                    }}
                  />
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <ConfirmButton />
                </div>
              </div>
            </Allotment.Pane>
          </Allotment>
        </ModalComponent>
      );
    },
    [projectsData, loadingProjects]
  );

  const handleShowModal = React.useCallback(() => {
    showModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  // const toggle = () => {
  //     setIsActive(!isActive);
  // };

  const isLinked = () => {
    if ((item.project_id.length === 0 && item.folder_id.length === 0) || item.project_id === 'UNASSOCIATED') {
      return false;
    }
    return true;
  };

  if (isLinked()) {
    return (
      <div ref={hoverRef}>
        {isHovered ? <LinkButton onClick={handleShowModal} /> : <LinkedButton disabled={!!isHovered} active />}
      </div>
    );
  }
  return <LinkButton onClick={handleShowModal} />;
}

const LoadingOverlay = () => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        height: '100%',
        background: 'none'
      }}>
      <div
        style={{
          width: '64px',
          height: '64px'
        }}>
        <LoadingSpinner />
      </div>
    </div>
  );
};

const InputContainer = styled(FlexBlock)`
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  background: none; //#2f2f2f
  font-size: 14px;

  ${FlexButton}::before {
    background-size: 16px;
  }
  ${FlexButton}:first-child::before {
    background-size: 18px;
    background-position-y: 65%;
  }
  ${Field} {
    color: #5f5f5f;
  }
  span {
    font-weight: 400;
    font-size: 14px;
  }
`;

const NameInputField = ({ item, refreshDevices }: INameInputFieldProps) => {
  const [fieldText, setFieldText] = React.useState<string>(item.name || '');
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const request = useAssociateDevice({
    id: item.id,
    payload: {
      name: fieldText,
      brand: item.brand,
      model: item.model,
      version: item.version
    },
    immediate: false
  });

  const handleOnChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldText(e.target.value);
  }, []);

  const handleToggle = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleConfirm = React.useCallback(async () => {
    await request.execute({
      id: item.id,
      payload: {
        name: fieldText,
        brand: item.brand,
        model: item.model,
        version: item.version
      }
    });
    refreshDevices();
  }, [fieldText, item.brand, item.id, item.model, item.version, refreshDevices, request]);

  return (
    <InputContainer type={FlexControlTypes.icon} span={isExpanded ? 8 : 8}>
      {isExpanded === true ? (
        <>
          <IconButton
            color="inherit"
            aria-label="edit device name"
            onClick={handleToggle}
            size="small"
            sx={{
              '&.MuiIconButton-root.MuiIconButton-sizeSmall': {
                fontSize: 18
              }
            }}>
            <EditPenIcon fontSize="inherit" />
          </IconButton>
          <Field
            style={{
              marginLeft: '0px',
              marginRight: '4px'
            }}
            span={isExpanded ? 14 : 4}
            placeholder="NAME"
            value={fieldText}
            onChange={handleOnChange}
          />
          <IconButton
            color="inherit"
            aria-label="cancel edit device name"
            onClick={handleToggle}
            size="small"
            sx={{
              '&.MuiIconButton-root.MuiIconButton-sizeSmall': {
                fontSize: 16
              }
            }}>
            <CancelEditIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="confirm edit device name"
            onClick={handleConfirm}
            size="small"
            sx={{
              '&.MuiIconButton-root.MuiIconButton-sizeSmall': {
                fontSize: 16
              }
            }}>
            {request.status === 'pending' ? (
              <LoadingSpinner width={8} height={8} />
            ) : (
              <ApproveEditIcon fontSize="inherit" />
            )}
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            color="inherit"
            aria-label="edit device name"
            onClick={handleToggle}
            size="small"
            sx={{
              '&.MuiIconButton-root.MuiIconButton-sizeSmall': {
                fontSize: 18
              }
            }}>
            <EditPenIcon fontSize="inherit" />
          </IconButton>
          <span>{item.name}</span>
        </>
      )}
    </InputContainer>
  );
};
interface DeviceBrowser {
  [x: string]: unknown;
}

const DeviceBrowser: NamedExoticComponent<DeviceBrowser> = memo(() => {
  const [deviceList, setDeviceList] = React.useState<{ nodes: IDevice[] }>({
    nodes: []
  });
  const [isRefreshDeviceList, setIsRefreshDeviceList] = React.useState<boolean>(false);
  // const [filterText, setFilterText] = React.useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDevice, setSelectedDevice] = React.useState<number | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFilterLinked, setIsFilterLinked] = React.useState<boolean>(false);

  const [FilterComponent, filterState] = useFilters();

  const data = React.useMemo(
    () => ({
      nodes: deviceList.nodes.filter((node) => {
        if (filterState === 'UNLINKED') {
          return node.project_name === '';
        }
        if (filterState === 'LINKED') {
          return node.project_name !== '';
        }
        if (filterState === 'ALL') {
          return node;
        }
        return node;
      })
    }),
    [deviceList.nodes, filterState]
  );

  const compareColumns = (a: string, b: string) => {
    return `${a}`.localeCompare(b, undefined, {
      sensitivity: 'base',
      numeric: true
    });
  };
  const sort: Sort<IDevice> = useSort(
    data,
    {
      onChange: onSortChange
    },
    {
      sortFns: {
        DEVICE: (array) => array.sort((a, b) => compareColumns(a.id as string, b.id as string)),
        NAME: (array) => array.sort((a, b) => compareColumns(a.name, b.name)),
        TYPE: (array) => array.sort((a, b) => compareColumns(a.type, b.type)),
        BRAND: (array) => array.sort((a, b) => compareColumns(a.brand, b.brand)),
        MODEL: (array) => array.sort((a, b) => compareColumns(a.model, b.model)),
        VERSION: (array) => array.sort((a, b) => compareColumns(a.version, b.version)),
        HOSTNAME: (array) => array.sort((a, b) => compareColumns(a.hostname, b.hostname)),
        PROJECT: (array) => array.sort((a, b) => compareColumns(a.project_name, b.project_name)),
        FOLDER: (array) => array.sort((a, b) => compareColumns(a.folder_name, b.folder_name)),
        STREAMS: (array) => array.sort((a, b) => (a.streams || []).length - (b.streams || []).length),
        LINK: (array) => array.sort((a, b) => compareColumns(a.project_id, b.project_id))
      },
      sortIcon: {
        size: '24px',
        position: SortIconPositions.Prefix,
        margin: '0px',
        iconDefault: <SortIcon fill="#676767" />,
        iconUp: <SortUpIcon fill="#a9a9a9" />,
        iconDown: <SortDownIcon fill="#a9a9a9" />
      },
      sortToggleType: SortToggleType.AlternateWithReset
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  function onSortChange(action: any, state: any): void {
    // handle on sort change...
  }

  const refreshDevices = React.useCallback(() => {
    setIsRefreshDeviceList(!isRefreshDeviceList);
  }, [isRefreshDeviceList]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // MARK: Column list should be: device, name, type, brand, model, version, hostname, project, folder, streams
  const columns = React.useMemo(
    (): Column<IDevice>[] => [
      {
        label: 'Device',
        renderCell: (item) => item.id,
        sort: { sortKey: 'DEVICE' },
        pinLeft: true
      },
      {
        label: 'Name',
        pinLeft: false,
        renderCell: (item) => {
          return (
            <Tooltip title="Update device name" shouldWrap placement="bottom">
              <NameInputField item={item as IDevice} refreshDevices={refreshDevices} />
            </Tooltip>
          );
        },
        sort: { sortKey: 'NAME' }
      },
      {
        label: 'Type',
        renderCell: (item) => item.type,
        sort: { sortKey: 'TYPE' }
      },
      {
        label: 'Brand',
        renderCell: (item) => item.brand,
        sort: { sortKey: 'BRAND' }
      },
      {
        label: 'Model',
        renderCell: (item) => item.model,
        sort: { sortKey: 'MODEL' }
      },
      {
        label: 'Version',
        renderCell: (item) => item.version,
        sort: { sortKey: 'VERSION' }
      },
      {
        label: 'Hostname',
        renderCell: (item) => item.hostname,
        sort: { sortKey: 'HOSTNAME' },
        pinLeft: true
      },
      {
        label: 'Project',
        renderCell: (item) => item.project_name,
        sort: { sortKey: 'PROJECT' },
        pinLeft: true
      },
      {
        label: 'Folder ID',
        renderCell: (item) => item.folder_id,
        hide: true
      },
      {
        label: 'Folder',
        renderCell: (item) => item.folder_name,
        sort: { sortKey: 'FOLDER' },
        pinLeft: true
      },
      {
        label: 'Project ID',
        renderCell: (item) => item.project_id,
        hide: true
      },
      {
        label: 'Streams',
        renderCell: (item) => item.streams,
        sort: { sortKey: 'STREAMS' },
        pinLeft: true
      },
      {
        label: 'Link',
        // pinRight: true,
        renderCell: (item) => {
          return (
            <Tooltip title="Link device" shouldWrap>
              <ProjectSelect item={item as IDevice} refreshDevices={refreshDevices} />
            </Tooltip>
          );
        },
        sort: { sortKey: 'LINK' },
        pinRight: true
      },
      {
        // MARK: Erik said that maybe we dont need to have a delete device functionality
        label: 'Unlink',
        // pinRight: true,
        renderCell: (item) => (
          <Tooltip title="Unlink device" shouldWrap>
            <DeleteActionIconButton item={item as IDevice} refreshDevices={refreshDevices} />
          </Tooltip>
        ),
        pinRight: true
      }
    ],
    [refreshDevices]
  );

  const numColumns = columns.filter((c) => !!c.hide === false).length;

  const LocalTheme = useTheme({
    Table: `
                --data-table-library_grid-template-columns: minmax(112px, 1.5fr) repeat(5, minmax(0, auto)) minmax(112px, 2fr) repeat(3, minmax(0, 1.5fr)) minmax(64px, auto) minmax(64px, auto);
        `,
    HeaderCell: `
            height: 48px;
            button span {
                margin-top: 6px;
              }
            &>div {
                overflow: visible;
            }

        `,
    Cell: `

        `
  });

  const [DeviceTableComponent] = useTable({
    columns,
    data,
    sort,
    // rowProps: {
    //     onDoubleClick: (row: unknown) => console.info(`Double Clicked row!`, row),

    // },
    // onInit: (nodes) => console.info(`Initializng table data ndoes..`, nodes),
    layout: {
      custom: true,
      resizedLayout: `

            repeat(${numColumns}, minmax(100px, auto));


            `,
      horizontalScroll: false
      // onLayoutChange: (widths) => console.info(`Firing onLayoutChange value: ${widths}`),
    },

    theme: LocalTheme as Theme
  });

  const request = useListDevices({ immediate: false });
  React.useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshDeviceList]);

  React.useEffect(() => {
    if (request.status === 'success') {
      setDeviceList({ nodes: request.value as IDevice[] });
    }
  }, [request.value, request.status]);

  return (
    <Wrapper>
      <FlexBlock
        span={10}
        color="red"
        type={FlexControlTypes.headerToolbarItem}
        style={{
          background: 'none',
          backgroundColor: '#1f1f1f',
          fontSize: '12px',
          fontWeight: 400,
          marginRight: 'auto',
          justifyContent: 'flex-start',
          padding: '0px',
          width: '100%'
        }}>
        <Tooltip title="Refresh devices">
          <IconButton color="inherit" aria-label="refresh devices" onClick={() => refreshDevices()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        <FilterComponent />
      </FlexBlock>

      {request.status === 'pending' && <LoadingOverlay />}
      {request.status === 'success' && <DeviceTableComponent />}
      {request.status === 'error' && request.error}
    </Wrapper>
  );
});
DeviceBrowser.displayName = 'DeviceBrowser';

export default DeviceBrowser;
