// import useFetch, { FetchOptions, FetchClientOptions, ResponseData } from

// import { retrieveAccessToken } from 'features/authorization/persistence';
import type { FetchClientOptions, UseFetchResult } from '../../../hooks/useFetchWithToken';
import useFetch from '../../../hooks/useFetchWithToken';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchApi = <T>(): UseFetchResult<T> => {
  const { getAccessTokenSilently } = useAuth0();
  const clientOptions: FetchClientOptions = {
    getAccessToken: async () => {
      const token = await getAccessTokenSilently();
      // Implement your logic to get the access token here.
      // For example, you might fetch it from localStorage or make an API call.
      return token;
    }
  };

  const [data, loading, error, triggerFetch] = useFetch<T>(clientOptions);

  return [data, loading, error, triggerFetch];
};

export default useFetchApi;
