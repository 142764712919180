import styled from 'styled-components';
import { defaultTheme as theme } from './theme';

export interface FieldProps {
  hasError?: boolean;
  vspan?: number;
  span?: number;
}

export const Field = styled.input<FieldProps>`
  background-color: ${theme.colors.grey30};
  color: ${theme.colors.white};
  font-size: ${theme.font.size.medium};
  box-sizing: border-box;

  height: ${(props) => (props.vspan !== undefined ? `calc(12px * ${props.vspan});` : '24px;')};

  width: ${(props) => (props.span !== undefined ? `calc(32px * ${props.span});` : '128px;')};

  padding: 10px 10px;
  border-radius: 2px;
  border: none;
  border-bottom: ${({ hasError }) => (hasError ? `3px solid ${theme.colors.danger}` : 'none')};
  transition: border-bottom-width 0.2s;
  margin: 1em 0;
  outline: none;
  ::placeholder {
    color: ${theme.colors.grey150};
    text-transform: uppercase;
  }
`;
