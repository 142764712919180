import React from 'react';
import { Stack, ToggleButtonGroup, ToggleButton, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

type FilterType = 'ALL' | 'LINKED' | 'UNLINKED';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IFilterProps {
  onChange?: (e: FilterType) => void;
}

export default function Filters({ onChange }: IFilterProps) {
  const [filter, setFilter] = React.useState<FilterType>('ALL');

  const handleFilter = (event: React.MouseEvent<HTMLElement>, newFilter: FilterType | null) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  React.useEffect(() => {
    onChange && onChange(filter);
  }, [filter, onChange]);

  return (
    <Stack
      direction="row"
      spacing={10}
      sx={{
        '& .MuiToggleButtonGroup-root': {
          marginLeft: 0
        }
      }}>
      <IconButton>
        {filter === 'ALL' && <FilterAltOffIcon htmlColor="#616161" />}
        {filter === 'LINKED' && <FilterAltIcon htmlColor="#616161" />}
        {filter === 'UNLINKED' && <FilterAltIcon htmlColor="#616161" />}
      </IconButton>
      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={handleFilter}
        color="standard"
        size="small"
        aria-label="text alignment"
        sx={{
          '& .MuiToggleButton-root': {
            color: '#616161',
            border: 'none',
            fontFamily: `'inter-regular','Helvetica Neue',sans-serif`,
            fontWeight: `500`,
            fontSize: `0.75rem`
          },
          '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: `#212121`,
            color: `#bdbdbd`
          }
        }}>
        <ToggleButton size="small" color="primary" value="ALL" aria-label="left aligned">
          ALL
        </ToggleButton>
        <ToggleButton size="small" color="primary" value="LINKED" aria-label="centered">
          LINKED
        </ToggleButton>
        <ToggleButton size="small" color="primary" value="UNLINKED" aria-label="right aligned">
          UNLINKED
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}
Filters.displayName = 'Filters';

export function useFilters() {
  const [filter, setFilter] = React.useState<FilterType>('ALL');

  const filerComponent = React.useMemo(
    () =>
      function FiltersMemo() {
        return <Filters onChange={(e) => setFilter(e)} />;
      },
    []
  );

  return [filerComponent, filter] as const;
}
