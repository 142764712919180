import { useAuth0 } from '@auth0/auth0-react';

import { ApplicationButton, ApplicationButtonIcon } from './Button';
import { LogoutIcon } from './LogoutIcon';

export function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <ApplicationButton
      opened={false}
      active={false}
      onClick={() => logout({ returnTo: `${window.location.origin}/home` })}
      style={{
        borderBottom: 'none',
        width: '2rem',
        paddingTop: '3px',
        boxSizing: 'border-box'
      }}>
      <ApplicationButtonIcon type="button" disabled={false}>
        <LogoutIcon />
      </ApplicationButtonIcon>
    </ApplicationButton>
  );
}
LogoutButton.displayName = 'LogoutButton';
