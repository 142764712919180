import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { NamedExoticComponent, PropsWithChildren } from 'react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export const Auth0Root: NamedExoticComponent<PropsWithChildren> = memo(({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env?.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN as string}/api/v2/`}
      redirectUri={`${window.location.origin}/home`}
      scope="read:current_user update:current_user_metadata"
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
});
Auth0Root.displayName = 'Auth0Root';
