/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components/macro';
import { defaultTheme as theme } from 'components/theme';
import { FlexButton } from 'components/FlexButton';
import { RemoveScroll } from 'react-remove-scroll';
import { Modal as MuiModal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
// https://css-tricks.com/considerations-styling-modal/

export interface IModalComponent {
  // [x: string]: number | boolean | string | (() => void) | (() => React.ReactNode) | undefined;
  [x: string]: any;
  hideModal?: () => void;
  showHeader?: boolean;
  headerText?: string;
  modalControls?: () => React.ReactNode;
}
export interface IModal {
  width?: number;
  height?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IModalScrollableContent {}

export interface IModalHeader {
  text?: string;
  renderButton?: () => React.ReactNode;
}

export const Modal = styled.div<IModal>`
  /* style */
  display: flex;
  flex-flow: column nowrap;
  background: ${theme.colors.grey40};
  /* Position */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: -200px 0 0 -200px; */

  /* Sizing  */
  padding-left: 0px;
  ${(props) => (props.width !== undefined ? `width: ${props.width}px;` : `width: 600px;`)}
  /* width: 600px; */
    max-width: 100%;
  ${(props) => (props.height !== undefined ? `height: ${props.height}px;` : `height: 400px;`)}
  /* height: 400px; */
    max-height: 100%;

  z-index: 1010;

  box-shadow: 0 0 25px 15px rgb(0 0 0 / 15%);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const ModalHeader = styled.div<IModalHeader>`
  ::before {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    color: ${theme.colors.grey90};
    font-weight: normal;

    ${(props) => (props.text !== undefined ? `content: '${props.text ?? ' '}';` : ``)}
  }
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  background: ${theme.colors.grey30};
  min-height: 48px;
  height: 48px;
  z-index: 1020;
`;
const ModalHeaderComponent = ({ renderButton, ...rest }: IModalHeader) => {
  return <ModalHeader {...rest}>{renderButton ? renderButton() : null}</ModalHeader>;
};

export const ModalScrollableContent = styled.div<IModalScrollableContent>`
  position: absolute;
  /* top: 32px; */
  left: 0;
  width: 100%;
  height: calc(100% - 32px);

  /* spacing as needed */
  padding: 0px 50px 8px 8px;

  /* let it scroll */
  overflow: auto;
  z-index: 1015; // drop it behind the header

  /* style scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #4c4c4c;
  }

  ::-webkit-scrollbar-track {
    background: #3f3f3f;
  }
`;

export const ModalBackgroundOverlay = styled.div`
  /* recommendation:
        don't focus on the number "1000" here, but rather,
        you should have a documented system for z-index and
        follow that system. This number should be pretty
        high on the scale in that system.
    */
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

export const ModalCloseButton = styled(FlexButton)`
  z-index: 1060;
  margin: 8px 8px 8px;
  margin-left: auto;
  height: 16px;
  width: 16px;
`;

const ModalControlsContainer = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  z-index: 1060;
`;
const LocalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  /* background-color: #000; */
  /* margin-top: 8px; */
  padding: 8px;
  padding-bottom: 0px;
  overflow-y: auto;
  overflow-x: clip;
`;
export const ModalComponent: React.FC<IModalComponent> = ({
  children,
  hideModal,
  showHeader,
  headerText,
  modalControls,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = React.useState(true);

  const hide = () => {
    setIsOpen(false);
    hideModal?.();
  };

  return (
    <RemoveScroll>
      <MuiModal open={isOpen} disableAutoFocus autoFocus={false} disableEnforceFocus disableRestoreFocus>
        <Modal {...rest}>
          {showHeader === undefined || showHeader === true ? (
            <ModalHeaderComponent
              text={headerText}
              renderButton={() => (
                <IconButton
                  onClick={() => hide()}
                  sx={{
                    color: theme.colors.grey90
                  }}>
                  <CloseIcon />
                </IconButton>
              )}
            />
          ) : null}
          {modalControls != null ? <ModalControlsContainer>{modalControls()}</ModalControlsContainer> : null}

          <LocalContainer>
            {/* If the modal needs to contain scrollable content - use the ModalScrollableContent container */}
            {/* <ModalScrollableContent> */}
            {children}
            {/* </ModalScrollableContent> */}
          </LocalContainer>
        </Modal>
      </MuiModal>
      <ModalBackgroundOverlay />
    </RemoveScroll>
  );
};
