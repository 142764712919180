import styled, { css } from 'styled-components';

export const ApplicationButtonIcon = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  font-size: 1rem;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.white};
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
`;

const AppButtonCollapsedStyle = css`
  border-radius: initial;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  width: 3rem;
  height: 3rem;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey900};
  /* &:hover {
    background-color: ${(props) => props.theme.colors.grey500};
  } */
  justify-content: center;
`;

const AppButtonExpandedStyle = css`
  margin: 0.275rem 0.5rem 0;
  align-items: center;
  height: 2rem;
  padding: 0 0.25rem;
  background-color: ${(props) => props.theme.colors.grey700};
  &:hover {
    background-color: ${(props) => props.theme.colors.grey425};
    cursor: pointer;
  }
  border-radius: 0.25rem;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
  }

  ${ApplicationButtonIcon} {
    padding-left: 0.25rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
const AppButtonSelectedStyle = css<{
  opened: boolean;
}>`
  background-color: ${(props) => props.theme.colors.grey425};
  opacity: 1;

  ${ApplicationButtonIcon} {
    svg {
      opacity: ${(props) => !props.opened && 1};
    }
  }
`;

export const ApplicationButton = styled.div<{
  opened: boolean;
  active: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  opacity: 0.67;
  & span {
    margin-left: 0.675rem;
  }
  &:hover {
    opacity: 1;
    cursor: pointer;
    & svg {
      opacity: 1;
    }
  }
  &:focus-visible {
    outline: none;
  }

  ${ApplicationButtonIcon} {
    svg {
      opacity: 0.67;
    }
  }
  ${(props) => (props.opened ? AppButtonExpandedStyle : AppButtonCollapsedStyle)}
  ${(props) => props.active && AppButtonSelectedStyle}
`;
